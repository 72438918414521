import styled from 'styled-components'

const width = '1000px'

export const Container = styled.div`
  width: ${width};
  margin: 0 auto;

  @media (max-width: ${width}) {
    width: 95%;
  }
`

export const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;

  @media (max-width: 900px) {
    display: block;
  }
`