import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Error from '../Error'

import { StyledForm, StyledField, StyledButton } from './styles'

const UserForm = ({ onSubmit, user }) => (
  <Formik
    initialValues={{ name: user.name, email: user.email }}
    validationSchema={Yup.object({
      name: Yup.string()
        .required('Required'),
      email: Yup.string()
        .email('Must be a valid email address')
        .required('Required')
        .max(255)
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      onSubmit(values)
    }}
  >
    {form => (
      <StyledForm>
        <Error />
        <StyledField
          type="text"
          name="name"
          placeholder='Full Name'
        />
        <StyledField
          type="email"
          name="email"
          placeholder="Email Address"
          autoComplete="username"
          autoFocus={true}
        />
        <StyledButton type="submit" value="update email address" disabled={form.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)

export default UserForm