import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EmbedCheckerButton from './button'
import libMap from './libs'
import { Title } from '../Style/headers'
import { fetchBusiness } from '../../actions/business'

const width = '920px'

const Container = styled.div`
  width: ${width};
  margin: 0 auto;

  @media (max-width: ${width}) {
    width: 95%;
  }
`

const NavList = styled.ul`
  font-size: 13px;
  border-bottom: 1px solid #dedede;
  margin: 0 5px 25px 5px;
`

const Tab = styled.li`
  color: #454545;
  display: inline-block;
  margin: 0 50px 15px 0;
  padding: 0 0 15px;
  cursor: pointer;
  font: 500 13px 'Inter', sans-serif;
  position: relative;
  top: 17px;

  ${({ active }) =>
    !!active &&
    `
    color: #4c75c0;
    font: 600 13px 'Inter', sans-serif;
    border-bottom: 2px solid #4c75c0;
  `}
`

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`

const Skip = styled.p`
  margin: 25px 0 40px;
  text-align: center;
  color: #525252;

  a {
    color: #525252;
  }
`

class Embed extends Component {
  constructor(props) {
    super(props)

    const libs = Object.keys(libMap)
    this.state = {
      status: 'unclicked',
      timer: null,
      libs: libs,
      tab: libs[0],
  }
    this.onClick = this.onClick.bind(this)
    this.setTab = this.setTab.bind(this)
  }

  componentDidMount() {
    this.props.fetchBusiness()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.business === this.props.business) { return false; }

    if (this.props.business.installed) {
      this.clearTimer(this.state.timer)
      this.setState({ status: 'installed', timer: null })
    }
  }

  componentWillUnmount() {
    this.clearTimer(this.state.timer)
  }

  clearTimer(timer) {
    if (!!timer) { clearTimeout(timer) }
  }

  render() {
    const { business } = this.props
    const { libs, tab } = this.state

    return (
      <Container>
        <Title>Nice work! The next step is adding the Tweeker script to your site.</Title>
        <Nav
          libs={libs}
          tab={tab}
          onClick={this.setTab}
          />
        <LibDetails
          businessId={business.id}
          embedVersion={business.embed_version}
          selectText={this.selectText}
          tab={tab}
        />
        <ButtonContainer>
          <EmbedCheckerButton
            business={business}
            onClick={this.onClick}
            status={this.state.status}
          />
        </ButtonContainer>
        <Skip>
          Not ready to install your script?
          {' '}
          <Link to='/plans'>Skip this step</Link> for now.
        </Skip>
      </Container>
    )
  }

  selectText(event) {
    window.getSelection().selectAllChildren(event.target)
  }

  onClick() {
    if (this.props.business.installed) {
      this.setState({ status: 'installed' })
    } else {
      const status = 'checking'
      const timer = setInterval(this.props.fetchBusiness, 2000)
      this.setState({ status, timer })
    }
  }

  setTab(event) {
    this.setState({ tab: event.target.innerHTML })
  }
}

const Nav = ({ libs, tab, onClick }) => (
  <NavList>
    {libs.map((lib, index) => (
      <Tab
        key={index}
        active={(lib === tab)}
        onClick={onClick}
      >{lib}</Tab>
    ))}
  </NavList>
)

const LibDetails = (props) => {
  if (!props.businessId) { return null }

  const { tab, ...thru } = props
  const Component = libMap[tab]

  return React.createElement(Component, thru)
}

Embed.propTypes = {
  business: PropTypes.object.isRequired,
  fetchBusiness: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    business: state.business,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchBusiness }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Embed)
