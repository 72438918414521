import queryString from 'query-string'
import { SHOPIFY_PLUGIN_URL } from '../constants/helpers'

export const firstUrl = (test) => (
  !!test.urls[0] ? test.urls[0].value : '/'
)

export const parseQuery = (props, key) => (
  queryString.parse(props.location.search)[key]
)

export const shopifyPaymentUrl = (shop, name) => (
  `${SHOPIFY_PLUGIN_URL}/payment?shop=${shop}&name=${name}`
)