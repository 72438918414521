import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Plan from './plan'
import { HalfwidthHeader, SubTitle } from '../Style/headers'
import { Section } from '../Style/container'
import { Container, PricingGrid } from './style'
import plans from '../../constants/plans'

class NewPlan extends Component {
  render() {
    const { current } = this.props

    return(
      <Container>
        <HalfwidthHeader>
          select a new payment plan. you wil be directed back to your
          payment page to finalize credit card details and your
          updated plan.
        </HalfwidthHeader>
        <Section>
          <PricingGrid>
            {plans.map((plan, index) => {
              const cta = current.name === plan.name ? 'Your Current Plan' : 'Change to'
              const disabled = current.name === plan.name ? true : false
              const url = `/plans/from/${current.name.toLowerCase()}/to/${plan.name.toLowerCase()}`
              return <Plan key={index} plan={plan} cta={cta} disabled={disabled} url={url} />
            })}
          </PricingGrid>
        </Section>
        <SubTitle>
          Need more than a million page views? Want to test unlimited domains?{' '}
          <Link to="/contact">Contact Us</Link>
        </SubTitle>
      </Container>
    )
  }
}

NewPlan.propTypes = {
  current: PropTypes.object.isRequired,
}

function mapStateToProps(_, ownProps) {
  return {
    current: filterPlan(ownProps),
  }
}

function filterPlan(props) {
  const param = props.match.params.plan
  return plans.filter(plan => plan.name.toLowerCase() === param)[0]
}

export default connect(mapStateToProps)(NewPlan)