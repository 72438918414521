import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchBusiness } from '../../actions/business'
import { SmallButton } from '../Style/button'
import { TooltipCss } from '../Style/tooltip'
import { backgroundImage } from '../../library/style'
import logo from '../../images/logo.svg'
import complete from '../../images/complete.svg'
import incomplete from '../../images/incomplete.svg'

const width = '432px'

const FrameControls = styled.div`
  font: 600 12px 'Inter', sans-serif;
  color: #333;
  position: fixed;
  bottom: 10vh;
  left: 50%;
  background: #fff;
  padding: 20px;
  transform: translateX(-50%);
  width: 40%;
  box-shadow: 0 0 5px -2px #2c2417;
  border-radius: 5px;

  @media (max-width: 1080px) {
    width: ${width};
  }

  @media (max-width: ${width}) {
    width: 95%;
  }
`

const Container = styled.div`
  float: left;
  position: relative;
  top: 4px;

  @media (max-width: ${width}) {
    float: none;
    margin-bottom: 25px;
  }
`

const Header = styled.h1`
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  position: relative;
  top: -2px;
`

const Logo = styled(Link)`
  ${backgroundImage(logo, 27, 85)}
  display: block;
`

const SubHeader = styled.h2`
  display: inline-block;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #ccc;

  @media (max-width: 1370px) {
    display: none;
  }
`

const Step = styled.h3`
  ${TooltipCss}
  display: inline-block;
  margin-right: 15px;
  color: #888;

  :before {
    ${backgroundImage(incomplete, 13, 12)}
    content: '';
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  &.passed {
    color: #333;

    :before {
      ${backgroundImage(complete, 13, 12)}
    }
  }
`

const StyledButton = styled(SmallButton)`
  background: #c9d5ec;
  cursor: not-allowed;
  float: right;

  :hover {
    background: #c9d5ec;
  }

  &.passed {
    background: #4c75c0;
    cursor: pointer;

    :hover {
      background: #476bae;
    }
  }

  @media (max-width: ${width}) {
    display: block;
    float: none;
  }
`

class Controls extends Component {
  constructor(props) {
    super(props)
    this.variantsPresent = this.variantsPresent.bind(this)
    this.goalsPresent = this.goalsPresent.bind(this)
    this.bothPresent = this.bothPresent.bind(this)
  }

  componentDidMount() {
    this.props.fetchBusiness()
  }

  componentDidUpdate(prevState) {
    const { variants, goals, postToFrame } = this.props
    // send variants to frame once they have loaded
    if (prevState.variants !== variants) {
      postToFrame(variants, 'send:variants')
    }

    if (prevState.goals !== goals) {
      postToFrame(goals, 'send:goals')
    }
  }

  render() {
    const variantsTooltip =
      "A variant is a change that you'd like to test. Click anywhere on your site above and start typing to change the copy of your site, then click save when you are done."
    const goalsTooltip =
      'A goal is what you want your test to increase. Typically this would be something like clicking a button or submitting a form. Click on part of your site above and click the "Set as Goal" toggle to make it a goal target.'
    const { test, business } = this.props
    // if the test already exists (aka it's being edited)
    // if the page already has the embed code
    // or if it's a new shopify business
    // then dont take to the embed page.
    const afterSavePath = (test.id || test.embedded || !!business.shopify)  ? '/' : '/embed'

    return (
      <FrameControls>
        <Container>
          <Header>
            <Logo to="/">Tweeker</Logo>
          </Header>
          <SubHeader>Set Up Your Test</SubHeader>
          <Step
            className={this.variantsPresent()}
            data-tooltip={variantsTooltip}
          >
            Add Variants
          </Step>
          <Step className={this.goalsPresent()} data-tooltip={goalsTooltip}>
            Set Goals
          </Step>
        </Container>
        <StyledButton as={Link} to={afterSavePath} className={this.bothPresent()}>
          Save Test
        </StyledButton>
      </FrameControls>
    )
  }

  variantsPresent() {
    if (this.props.variants.length > 0) {
      return 'passed'
    }
  }

  goalsPresent() {
    if (this.props.goals.length > 0) {
      return 'passed'
    }
  }

  bothPresent() {
    if (this.props.variants.length > 0 && this.props.goals.length > 0) {
      return 'passed'
    }
  }
}

Controls.propTypes = {
  url: PropTypes.string.isRequired,
  variants: PropTypes.array.isRequired,
  goals: PropTypes.array.isRequired,
  test: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,

  postToFrame: PropTypes.func.isRequired,
  fetchBusiness: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    variants: state.variants,
    goals: state.goals,
    test: state.test,
    business: state.business,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBusiness,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Controls)
