import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getFlash, clearFlash } from '../../actions/flash'
import { clearFlashStorage } from '../../library/redirect'

const FlashContainer = styled.div`
  position: relative;
`

const StyledFlash = styled.div`
  background: ${props => (props.error ? '#ffeeee' : '#f5fffd')};
  color: ${props => (props.error ? '#fa7777' : '#50c4b7')};
  border-color: ${props => (props.error ? '#ffcbcb' : '#ddf3f0')};
  border-style: solid;
  border-width: 0 0 1px 0;
  text-align: center;
  padding: 15px;

  a {
    color: ${props => (props.error ? '#fa7777' : '#50c4b7')};
  }
`

const CloseFlash = styled.a`
  color: ${props => (props.error ? '#fa7777' : '#50c4b7')};
  position: absolute;
  right: 18px;
  top: 13px;
  font-size: 18px;
  cursor: pointer;
`

class Flash extends Component {
  componentDidMount() {
    this.props.getFlash()
    clearFlashStorage()
  }

  render() {
    const { flash, clearFlash } = this.props

    if (!flash || !flash.message) {
      return false
    }

    const { message, error } = flash

    return (
      <FlashContainer>
        <StyledFlash error={error} dangerouslySetInnerHTML={{__html: message }}></StyledFlash>
        <CloseFlash error={error} onClick={clearFlash}>
          ✕
        </CloseFlash>
      </FlashContainer>
    )
  }
}

Flash.propTypes = {
  flash: PropTypes.object.isRequired,

  getFlash: PropTypes.func.isRequired,
  clearFlash: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    flash: state.flash,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFlash,
      clearFlash,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Flash)
