import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/test'
import { setVariants } from '../variants'
import { setGoals } from '../goals'

export const fetchTest = id => dispatch => {
  return api
    .fetchTest(id)
    .then(response => {
      dispatch({
        type: actionTypes.FETCH_TEST_SUCCESS,
        payload: response,
      })
    })
    .catch(handleFetchTestError)
}

export const fetchTestByUrl = url => dispatch => {
  if (!url) {
    return
  }

  return api
    .fetchTestByUrl(url)
    .then(response => {
      setVariants(response.variants)(dispatch)
      setGoals(response.goals)(dispatch)

      dispatch({
        type: actionTypes.FETCH_TEST_SUCCESS,
        payload: response,
      })
    })
    .catch(handleFetchTestError)
}

const handleFetchTestError = error => {
  console.error('Crank Error:', error)
}

export const fetchTests = () => dispatch => {
  return api
    .fetchTests()
    .then(response => {
      dispatch({
        type: actionTypes.FETCH_TESTS_SUCCESS,
        payload: response,
      })
    })
    .catch(handleFetchTestsError)
}

export const deleteTest = id => dispatch => {
  return api.deleteTest(id).then(
    dispatch({
      type: actionTypes.DELETE_TEST_SUCCESS,
      payload: id,
    })
  )
}

export const updateTest = (id, body) => dispatch => {
  return api.updateTest(id, body)
    .then(response => {
      dispatch({
        type: actionTypes.UPDATE_TEST_SUCCESS,
        payload: response,
      })
    })
}

export const setTest = body => dispatch => {
  dispatch({
    type: actionTypes.SET_TEST,
    payload: body,
  })

}

const handleFetchTestsError = error => {
  console.log(error)
}
