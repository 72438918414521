import React, { Component } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import { TextfieldStyles } from './input'
import Label from '../Form/label'
import { backgroundImage } from '../../library/style'
import caret from '../../images/caret.svg'

export const DropdownContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;

  :after {
    content: '';
    ${backgroundImage(caret, 8, 11)}
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 22px;
  }
`

export const StyledDropdown = styled.select`
  ${TextfieldStyles}
  appearance: none;
  cursor: pointer;
`

class Select extends Component {
  render() {
    const { name, label, options, ...props } = this.props

    return(
      <>
        <Label name={name} label={label} showLabel />
        <DropdownContainer>
          <Field id={name} name={name}>
            {({ field }) => (
              <StyledDropdown
                as='select'
                name={name}
                {...props}
                {...field}
              >
                {options.map((option, index) => (
                  <option key={index} value={option} label={option} />
                ))}
              </StyledDropdown>
            )}
          </Field>
        </DropdownContainer>
      </>
    )
  }
}

export default Select