import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function set(name, data) {
  return cookies.set(name, data, {
    path: '/',
  })
}

export function get(name) {
  return cookies.get(name)
}

export function remove(name) {
  return cookies.remove(name)
}
