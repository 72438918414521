import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import Header from './header'
import Chart from './chart'
import Table from './table'
import { InlineButton } from '../Style/button'
import { firstUrl } from '../../library/helpers'
import { fetchTest } from '../../actions/test'
import { setFlash } from '../../actions/flash'
import { fetchBusiness } from '../../actions/business'
import { requiresEmbed } from '../Shared/requiresEmbed'

const Title = styled.h1`
  font: 600 24px 'Inter', sans-serif;
  color: #454545;
  margin-bottom: 30px;

  a {
    color: #454545;
  }
`

const TestMore = styled.div`
  font: 500 16px 'Inter', sans-serif;
  color: #3d3d3d;
  text-align: center;
  margin: 40px 0;

  a {
    margin-left: 15px;
  }

  @media (max-width: 500px) {
    a {
      display: block;
      margin-top: 25px;
    }
  }
`

class Test extends Component {
  componentDidMount() {
    this.props.fetchTest(this.props.match.params.id)
    this.props.fetchBusiness()
  }

  render() {
    const { test } = this.props
    const url = firstUrl(test)

    return (
      <>
        <Title>
          Test results for{' '}
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Title>
        <Header test={test} url={url} />
        <Chart test={test} url={url} />
        <Table test={test} />
        <TestMore>
          have another idea you'd like to test?
          <InlineButton as={Link} to={'/tests?url=' + url}>
            add another variant
          </InlineButton>
        </TestMore>
      </>
    )
  }
}

Test.propTypes = {
  test: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,

  fetchTest: PropTypes.func.isRequired,
  fetchBusiness: PropTypes.func.isRequired,
  setFlash: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    test: state.test,
    business: state.business,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTest,
      fetchBusiness,
      setFlash,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(requiresEmbed(Test))
