import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Input from '../Form/input'
import TestImage from '../TestDetail/image'
import TestDetail from '../TestDetail'
import UrlForm from '../UrlForm'
import { InlineButton } from '../Style/button'
import { fetchTest, updateTest } from '../../actions/test'
import { fetchBusiness } from '../../actions/business'
import { newUrl, deleteUrl } from '../../actions/url'

const Title = styled.h1`
  font: 600 24px 'Inter', sans-serif;
  color: #454545;
  margin-bottom: 30px;
`

const TestInfo = styled.div`
  margin-bottom: 30px;
`

const ImageContainer = styled.div`
  width: 49%;
  margin-right: 2%
  display: inline-block;
  vertical-align: top;
`

const DetailContainer = styled.div`
  display: inline-block;
  vertical-align: top;
`

const StyledForm = styled(Form)`
  margin-bottom: 30px;
`

const Subheader = styled.h3`
  font: 600 13px 'Inter','sans-serif';
  color: #4566a4;
  margin-bottom: 7px;
`

const UrlList = styled.ul`
  margin-bottom: 30px;
`

const UrlListItem = styled.li`
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 7px;
  overflow: hidden;
  margin-bottom: 15px;
`

const UrlValue = styled.h4`
  float: left;
`

const UrlDelete = styled.div`
  float: right;
  color: #fa7777;
  cursor: pointer;
`

const WildcardExplainer = styled.p`
  margin-bottom: 30px;
  color: #7e7e7e;
`

class TestEdit extends Component {
  constructor(props) {
    super(props)
    this.updateTest = this.updateTest.bind(this)
    this.newUrl = this.newUrl.bind(this)
  }

  componentDidMount() {
    this.props.fetchTest(this.props.match.params.id)
    this.props.fetchBusiness()
  }

  render() {
    const { test, business } = this.props

    return (
      <>
        <Title>Edit {test.name} Test</Title>
        <TestInfo>
          <ImageContainer>
            <TestImage test={test} business={business} />
          </ImageContainer>
          <DetailContainer>
            <TestDetail test={test} business={business} />
          </DetailContainer>
        </TestInfo>
        <TestForm name={test.name} business={business} onSubmit={this.updateTest} />
        <Subheader>URLs</Subheader>
        <UrlList>
          {test.urls.map((url, index) =>
            <UrlListItem key={index}>
              <UrlValue>{url.value}</UrlValue>
              <UrlDelete onClick={() => this.deleteUrl(url.id)}>Delete</UrlDelete>
            </UrlListItem>
          )}
        </UrlList>
        <UrlForm
          onSubmit={this.newUrl}
          placeholder='https://another-url.com'
          submit='+ add URL'
        />
        <WildcardExplainer>
          You can also add wildcard matchers to your url with *. ex: https://yoursite.com/user/*/profile
        </WildcardExplainer>
      </>
    )
  }

  updateTest(values) {
    const { test, updateTest } = this.props
    updateTest(test.id, values)
  }

  deleteUrl(id) {
    if (window.confirm('Are you sure you want to delete this url?')) {
      this.props.deleteUrl(id)
    }
  }

  newUrl(values) {
    const { test, newUrl } = this.props
    const body = { ...{ test_id: test.id }, ...values }
    newUrl(body)
  }
}

const TestForm = ({ name, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{ name: name }}
    validationSchema={Yup.object({
      name: Yup.string()
        .required('Required')
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      onSubmit(values)
    }}
  >
    {form => (
      <StyledForm>
        <Input
          type='text'
          name='name'
          placeholder='Name this test'
          label='Test Name'
          showLabel
        />
        <InlineButton type='submit' value='Save' disabled={form.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)



TestEdit.propTypes = {
  test: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,

  fetchTest: PropTypes.func.isRequired,
  fetchBusiness: PropTypes.func.isRequired,
  updateTest: PropTypes.func.isRequired,
  newUrl: PropTypes.func.isRequired,
  deleteUrl: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    test: state.test,
    business: state.business,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTest,
      fetchBusiness,
      updateTest,
      newUrl,
      deleteUrl,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TestEdit)
