import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TestImage from '../TestDetail/image'
import TestDetail from '../TestDetail'
import { fetchTests } from '../../actions/test'
import { fetchBusiness } from '../../actions/business'
import { setFlash } from '../../actions/flash'
import { requiresEmbed } from '../Shared/requiresEmbed'
import { SmallButton } from '../Style/button'

const width = '800px'

const Header = styled.div`
  overflow: hidden;
  margin: -30px 0 40px;
`

const Title = styled.h2`
  float: left;
  font: 600 24px 'Inter', sans-serif;
  color #454545;
`

const FloatButton = styled(SmallButton)`
  float: right;
`

const TestList = styled.ul`
  display: grid;
  grid-template-columns: 31.333% 31.333% 31.333%;
  grid-gap: 3%;

  @media (max-width: ${width}) {
    display: block;
  }
`

const ListItem = styled.li`
  display: inline-block;
  vertical-align: top;
  margin-bottom: 40px;
  overflow: hidden;

  @media (max-width: ${width}) {
    display: block;
  }
`

const height = '255px'

const AddTest = styled.li`
  background: #f7f9fc;
  border: 2px dashed #bed2ea;
  border-radius: 10px;
  height: ${height};
  line-height: ${height};
  text-align: center;

  :hover {
    border-color: #91a1b5;
  }
`

const AddTestLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;
  color: #bed2ea;
  font-size: 16px;
  text-decoration: none;
  width: 100%;

  :hover {
    color: #91a1b5;
  }
`

class Home extends Component {
  componentDidMount() {
    this.props.fetchTests()
    this.props.fetchBusiness()
  }

  render() {
    const { business } = this.props
    return (
      <>
        <Header>
          <Title>My Tests</Title>
          <FloatButton as={Link} to="/tests/new">
            + add test
          </FloatButton>
        </Header>
        <TestList>
          {this.props.tests.map((test, index) => (
            <ListItem key={index}>
              <TestImage test={test} business={business} />
              <TestDetail test={test} />
            </ListItem>
          ))}
          <AddTest>
            <AddTestLink to="/tests/new">+ add test</AddTestLink>
          </AddTest>
        </TestList>
      </>
    )
  }
}

Home.propTypes = {
  tests: PropTypes.array.isRequired,
  business: PropTypes.object.isRequired,

  fetchTests: PropTypes.func.isRequired,
  fetchBusiness: PropTypes.func.isRequired,
  setFlash: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    tests: state.tests,
    business: state.business,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTests,
      fetchBusiness,
      setFlash,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(requiresEmbed(Home))
