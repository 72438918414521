import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const payment = (state = initialState.payment, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PAYMENT_SUCCESS:
    case actionTypes.FETCH_PAYMENT_SUCCESS:
      return setPayment(state, action.payload)
    default:
      return state
  }
}

const setPayment = (state, data) => {
  return { ...state, ...data }
}

export default payment
