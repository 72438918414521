import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import plans from '../../constants/plans'
import PaymentForm from './form'
import Plan from '../Plans/plan'
import { Title } from '../Style/headers'
import { Section } from '../Style/container'
import { backgroundImage } from '../../library/style'
import x from '../../images/x.svg'
import { savePayment } from '../../actions/payment'

const breakpoint = '1000px'

const Container = styled.div`
  margin-bottom: 50px;
`

const BackX = styled(Link)`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 0;

  :before {
    ${backgroundImage(x, 11, 11)}
    content: '';
    display: block;
  }
`

const PlanContainer = styled.div`
  float: left;
  margin-right: 45px;
  width: 350px;

  @media (max-width: ${breakpoint}) {
    width: 100%;
    margih-right: 0;
    float: none;
    margin: 0 auto;
  }
`

class Payment extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  render() {
    const { plan } = this.props

    return (
      <>
        <Title>
          select a payment plan that suits your projects best. your first two
          weeks are on us, so you can see if tweeker fits your needs.
        </Title>
        <Container>
          <PlanContainer>
            <Section>
              <Plan plan={plan} showButton={false} />
              <BackX to="/plans">Back</BackX>
            </Section>
          </PlanContainer>
          <PaymentForm
            submitValue='start my 2 week free trial'
            onSubmit={this.onSubmit}
          />
        </Container>
      </>
    )
  }

  onSubmit(token) {
    const { plan, savePayment } = this.props

    savePayment(
      { stripe: token, plan: plan.name },
      this.props.afterPayPath,
    )
  }
}

Payment.propTypes = {
  plan: PropTypes.object.isRequired,
  savePayment: PropTypes.func.isRequired,
  afterPayPath: PropTypes.string.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    plan: filterPlan(ownProps),
    afterPayPath: getAfterPayPath(state),
  }
}

function getAfterPayPath(state) {
  return !!state.test.id ? `/tests/${state.test.id}` : '/'
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ savePayment }, dispatch)
}

function filterPlan(props) {
  const param = props.match.params.plan
  return plans.filter(plan => plan.name.toLowerCase() === param)[0]
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)