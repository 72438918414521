import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { InlineButton } from '../Style/button'

const Container = styled.div`
  @media (max-width: 900px) {
    margin-bottom: 50px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const Name = styled.h3`
  font: 600 20px 'Inter', sans-serif;
  color: #4c75c0;
  margin-bottom: 15px;
`

const Cost = styled.h3`
  font: 600 64px 'Inter', sans-serif;
  color: #4c75c0;
  letter-spacing: -0.3rem;
`

const Per = styled.span`
  font: 600 15px 'Inter', sans-serif;
  letter-spacing: -0.05rem;
`

const PageViews = styled.h4`
  font: 600 17px 'Inter', sans-serif;
  color: #454545;
  margin: 10px 0 20px;
`

const Features = styled.ul`
  font: 600 17px 'Inter', sans-serif;
  color: #888;
  margin-bottom: 20px;
`

const Pricing = ({ name, cost, views, url, children, showButton, cta, disabled }) => (
  <Container>
    <Name>{name}</Name>
    <Cost>
      ${cost}
      <Per>/ month</Per>
    </Cost>
    <PageViews>Up to {views} page views</PageViews>
    <Features>{children}</Features>
    {showButton && (
      <ActionButton url={url} cta={cta} name={name} disabled={disabled} />
    )}
  </Container>
)

const ActionButton = ({url, cta, name, disabled}) => {
  if (disabled) {
    return(
      <InlineButton as='button' disabled={true}>
        {cta}
      </InlineButton>
    )
  } else {
    return(
      <InlineButton as="a" href={url}>
        {cta} {name} Plan
      </InlineButton>
    )
  }
}

Pricing.propTypes = {
  name: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  views: PropTypes.string.isRequired,
  url: PropTypes.string,
  children: PropTypes.node.isRequired,
  showButton: PropTypes.bool.isRequired,
  cta: PropTypes.string.isRequired,
}

Pricing.defaultProps = {
  showButton: true,
  cta: 'Sign up for',
}

export default Pricing
