import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const error = (state = initialState.error, action) => {
  switch (action.type) {
    case actionTypes.FETCH_START:
      return null
    case actionTypes.FETCH_ERROR:
      return action.payload
    default:
      return state
  }
}

export default error
