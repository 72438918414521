import { set, remove } from './storage'
import * as constants from '../constants/helpers'

export function redirect(location = '/', message = null, error = false) {
  if (message) {
    set(constants.FLASH_KEY, { message, error })
  }
  window.location = location
}

export function clearFlashStorage() {
  remove(constants.FLASH_KEY)
}

export function loginExpired(currentUser) {
  const date = new Date().getTime()
  return !!currentUser && !!currentUser.expires && (date < currentUser.expires)
}
