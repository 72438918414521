import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const tests = (state = initialState.tests, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TESTS_SUCCESS:
      return action.payload
    case actionTypes.DELETE_TEST_SUCCESS:
      return state.filter(test => test.id !== action.payload)
    case actionTypes.UPDATE_TEST_SUCCESS:
    case actionTypes.SAVE_URL_SUCCESS:
    case actionTypes.DELETE_URL_SUCCESS:
      return updateTest(state, action.payload)
    default:
      return state
  }
}

function updateTest(state, test) {
  return state.map(t => (t.id === test.id) ? test : t)
}

export default tests
