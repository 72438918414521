export const IFRAME_ROOT_URL = process.env.REACT_APP_IFRAME_ROOT_URL
export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL
export const EMBED_ROOT_URL = process.env.REACT_APP_EMBED_ROOT_URL
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY
export const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY
export const SHOPIFY_PLUGIN_URL = process.env.REACT_APP_SHOPIFY_PLUGIN_URL
export const AUTH_KEY = '_tweeker_auth'
export const FLASH_KEY = '_tweeker_flash'
export const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID
// eslint-disable-next-line
export const URL_REGEX = /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/