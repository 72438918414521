import { combineReducers } from 'redux'
import test from './test'
import tests from './tests'
import goal from './goal'
import goals from './goals'
import business from './business'
import currentUser from './auth'
import flash from './flash'
import payment from './payment'
import error from './error'
import variants from './variants'
import header from './header'
import plan from './plan'

export default combineReducers({
  test,
  tests,
  goal,
  goals,
  business,
  currentUser,
  flash,
  payment,
  error,
  variants,
  header,
  plan,
})
