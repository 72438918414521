import HTML from './html'
import Javascript from './javascript'
import React from './react'
import Wordpress from './wordpress'
import Shopify from './shopify'

export default {
  HTML,
  Javascript,
  React,
  Wordpress,
  Shopify
}