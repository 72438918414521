import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Controls from './controls'
import { fetchTestByUrl, setTest } from '../../actions/test'
import { saveVariant, saveOriginal, deleteVariant } from '../../actions/variant'
import { saveGoal, deleteGoal } from '../../actions/goal'
import { setHeader } from '../../actions/header'
import { IFRAME_ROOT_URL } from '../../constants/helpers'
import { parseQuery } from '../../library/helpers'

const Loader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: white;
`

const LoaderInfo = styled.div`
  font: 500 24px 'Inter', sans-serif;
  letter-spacing: -0.04em;
  line-height: 1.8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

class Frame extends Component {
  constructor(props) {
    super(props)
    this.onMessage = this.onMessage.bind(this)
    this.onLoad = this.onLoad.bind(this)
    this.postToFrame = this.postToFrame.bind(this)
    this.loaderRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessage)
    this.iframe.addEventListener('load', this.onLoad)
    this.props.setHeader({ show: false })
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage)
    this.iframe.removeEventListener('load', this.onLoad)
    this.props.setHeader({ show: true })
  }

  onLoad() {
    this.loaderRef.current.style.display = 'none'
    this.props.fetchTestByUrl(this.props.url)
  }

  render() {
    const { url } = this.props

    return (
      <>
        <Controls postToFrame={this.postToFrame} url={url} />
        <Loader ref={this.loaderRef}>
          <LoaderInfo>Loading {url}</LoaderInfo>
        </Loader>
        <IFrame
          src={IFRAME_ROOT_URL + '/?url=' + url}
          title="editor"
          frameborder="0"
          ref={f => (this.iframe = f)}
        ></IFrame>
      </>
    )
  }

  onMessage(event) {
    if (event.origin !== IFRAME_ROOT_URL) {
      return
    }

    const { type, payload } = event.data
    const { url } = this.props

    if (type === 'save:variant') {
      this.props.saveVariant(payload, url)
    } else if (type === 'save:original') {
      this.props.saveOriginal(payload, url)
    } else if (type === 'save:goal') {
      this.props.saveGoal(payload, url)
    } else if (type === 'delete:variant') {
      this.props.deleteVariant(payload)
    } else if (type === 'delete:goal') {
      this.props.deleteGoal(payload)
    } else if (type === 'set:embedded') {
      this.props.setTest({ embedded: payload})
    }
  }

  postToFrame(data, type = 'send:variants') {
    this.iframe.contentWindow.postMessage(
      {
        type: type,
        payload: data,
      },
      IFRAME_ROOT_URL
    )
  }
}

Frame.propTypes = {
  url: PropTypes.string.isRequired,
  variants: PropTypes.array.isRequired,

  fetchTestByUrl: PropTypes.func.isRequired,
  saveVariant: PropTypes.func.isRequired,
  saveOriginal: PropTypes.func.isRequired,
  saveGoal: PropTypes.func.isRequired,
  deleteVariant: PropTypes.func.isRequired,
  deleteGoal: PropTypes.func.isRequired,
  setHeader: PropTypes.func.isRequired,
  setTest: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    url: parseQuery(ownProps, 'url'),
    variants: state.variants,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTestByUrl,
      saveVariant,
      saveOriginal,
      saveGoal,
      deleteVariant,
      deleteGoal,
      setHeader,
      setTest,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Frame)
