import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/goal'

export const saveGoal = (data, url) => dispatch => {
  dispatch({
    type: actionTypes.MERGE_GOAL,
    payload: data,
  })

  return api
    .saveGoal(data, url)
    .then(response => {
      dispatch({
        type: actionTypes.SAVE_GOAL_SUCCESS,
        payload: response,
      })
    })
    .catch(handleSaveGoalError)
}

export const deleteGoal = id => dispatch => {
  return api.deleteGoal(id).then(
    dispatch({
      type: actionTypes.DELETE_GOAL_SUCCESS,
      payload: id,
    })
  )
}

const handleSaveGoalError = error => {
  alert(error.message)
}
