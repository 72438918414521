import React from 'react'
import { Block, Tag, Step, Container } from './style'
import * as constants from '../../../constants/helpers'

const HTML = ({ businessId, embedVersion, selectText }) => (
  <Container>
    <Step>
      Copy and paste the below code into your site,
      making sure it appears before the closing <Tag>{'</body>'}</Tag>
      tag of your webpage template. This way, tests from Tweeker can
      appear on any page of your site that you like.
    </Step>
    <Block onClick={selectText}>
      <Code businessId={businessId} embedVersion={embedVersion} />
    </Block>
    <Step>Once you’ve pasted in the code, click below to check if it’s installed correctly.</Step>
  </Container>
)

const Code = ({ businessId, embedVersion }) => {
  const url = `${constants.EMBED_ROOT_URL}/${embedVersion}.js`

  return(
    `<script>
  window.TweekerSettings = {
    businessId: '${businessId}'
  }
</script>

<script>
  (function(){
    var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
    i.src = "${url}";
    e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
  })();
</script>`
  )
}

export default HTML