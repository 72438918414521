import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'

const ErrorContainer = styled.h2`
  margin: 25px 0;
  background: #ffeeee;
  color: #fa7777;
  padding: 10px;
  line-height: 1.4;
  border: 1px solid #ffcbcb;
  border-radius: 5px;
  font-size: 13px;
`

class Error extends Component {
  render() {
    const { error } = this.props

    if (error) {
      return(
        <ErrorContainer>{error}</ErrorContainer>
      )
    } else {
      return null
    }
  }
}

Error.propTypes = {
  error: PropTypes.string
}

function mapStateToProps(state) {
  return {
    error: state.error,
  }
}

export default connect(mapStateToProps)(Error)