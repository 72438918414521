import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import PasswordFields from '../Auth/password_fields'
import { newPassword } from '../../actions/passwords'
import Auth from '../Auth'
import Error from '../Error'
import { StyledForm, Header } from '../Style/auth'
import { Button } from '../Style/button'

class NewPassword extends Component {
  render() {
    const {
      newPassword,
      match: {
        params: {
          password_reset_token
        }
      }
    } = this.props

    return (
      <Auth>
        <Header>set a new tweeker password</Header>
        <Error />
        <NewPasswordForm
          newPassword={newPassword}
          password_reset_token={password_reset_token}
        />
      </Auth>
    )
  }
}

const NewPasswordForm = ({ newPassword, password_reset_token }) => (
  <Formik
    initialValues={{
      password: '',
      password_confirmation: '',
      password_reset_token: password_reset_token
    }}
    validationSchema={Yup.object({
      password: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters'),
      password_confirmation: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      password_reset_token: Yup.string()
        .required()
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      newPassword(values)
    }}
  >
    {form => (
      <StyledForm>
        <Field
          type="hidden"
          name="password_reset_token"
          value={password_reset_token}
        />
        <PasswordFields />
        <Button type="submit" value="reset my password" disabled={form.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)

function mapDispatchToProps(dispatch) {
  return {
    newPassword: bindActionCreators(newPassword, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(NewPassword)
