import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import plans from '../../constants/plans'
import PaymentForm from '../Payment/form'
import ExistingPayment from './existingPayment'
import Editable from './editable'
import UserForm from './userForm'
import PasswordForm from './passwordForm'
import Plan from '../Plans/plan'
import { Title, SectionTitle } from '../Style/headers'
import { Section } from '../Style/container'
import { fetchBusiness } from '../../actions/business'
import { fetchPayment, updatePayment } from '../../actions/payment'
import { getCurrentUser, updateUser } from '../../actions/auth'
import { deleteBusiness } from '../../actions/business'
import { updatePassword } from '../../actions/passwords'
import { backgroundImage } from '../../library/style'
import back from '../../images/back.svg'
import deleteIcon from '../../images/delete.svg'

const breakpoint = '1000px'

const Container = styled.div`
  margin-bottom: 50px;
`

const PlanContainer = styled.div`
  float: left;
  margin-right: 45px;
  width: 350px;

  @media (max-width: ${breakpoint}) {
    width: 100%;
    margih-right: 0;
    float: none;
    margin: 0 auto;
  }
`

const SharedStyles = css`
  display: inline-block;
  font: 600 15px 'Inter', sans-serif;

  :before {
    content: '';
    display: inline-block;
    margin-right: 10px;
  }
`

const StyledChangeLink = styled(Link)`
  ${SharedStyles}
  margin-top: 50px;
  color: #4c75c0;

  :before {
    ${backgroundImage(back, 9, 13)};
    transform: rotate(90deg);
  }
`

const DeleteLink = styled.a`
  ${SharedStyles}
  cursor: pointer;
  color: #fa7777;
  text-decoration: underline;

  :before {
    ${backgroundImage(deleteIcon, 13, 12)};
    position: relative;
    top: 2px;
  }
`

const Overflow = styled.div`
  overflow: hidden;
`

class Settings extends Component {
  constructor(props) {
    super(props)
    const search = queryString.parse(props.location.search)
    this.state = {
      showPaymentForm: !!search.showPaymentForm,
      showUserForm: !!search.showUserForm,
      showPasswordForm: !!search.showPasswordForm,
    }
    this.submitPayment = this.submitPayment.bind(this)
    this.deleteAccount = this.deleteAccount.bind(this)
  }

  componentDidMount() {
    this.props.fetchBusiness()
    this.props.fetchPayment()
    this.props.getCurrentUser()
  }

  render() {
    const { plan, payment, business, currentUser } = this.props
    const { showPaymentForm, showUserForm, showPasswordForm } = this.state

    return (
      <>
        <Title>
          edit your payment settings, change your payment plan,
          change email address or password,
          or delete your account.
        </Title>
        <Container>
          <PlanContainer>
            <Section>
              {plan &&
                <>
                  <Plan plan={plan} showButton={false} />
                  <ChangeLink plan={plan} />
                </>
              }
            </Section>
            <DeleteLink onClick={this.deleteAccount}>
              Delete my Tweeker Account
            </DeleteLink>
          </PlanContainer>
          <Overflow>
            {!business.shopify &&
              <Section>
                <PaymentArea
                  showPaymentForm={showPaymentForm}
                  payment={payment}
                  business={business}
                  onClick={() => this.setState({ showPaymentForm: true })}
                  onSubmit={this.submitPayment}
                />
              </Section>
            }

            <Section>
              <SectionTitle>My Account info</SectionTitle>
              <UserArea
                showUserForm={showUserForm}
                onClick={() => this.setState({ showUserForm: true })}
                user={currentUser}
                onSubmit={(values) => this.props.updateUser(values)}
              />
              <PasswordArea
                showPasswordForm={showPasswordForm}
                onClick={() => this.setState({ showPasswordForm: true })}
                onSubmit={(values) => this.props.updatePassword(values)}
              />
            </Section>
          </Overflow>
        </Container>
      </>
    )
  }

  deleteAccount() {
    if (window.confirm('Are you sure you want to delete your account?')) {
      this.props.deleteBusiness()
    }
  }

  submitPayment(token) {
    this.props.updatePayment({ stripe: token })
  }
}

const PaymentArea = ({ showPaymentForm, payment, onClick, onSubmit }) => {
  if (showPaymentForm) {
    return <PaymentForm submitValue='save new credit card' onSubmit={onSubmit} />
  } else {
    return <ExistingPayment payment={payment} onClick={onClick} />
  }
}

const UserArea = ({ showUserForm, onClick, onSubmit, user }) => {
  if (showUserForm) {
    return <UserForm onSubmit={onSubmit} user={user} />
  } else {
    return(
      <Editable onClick={onClick}>
        Name and Email Address
      </Editable>
    )
  }
}

const PasswordArea = ({ showPasswordForm, onClick, onSubmit }) => {
  if (showPasswordForm) {
    return <PasswordForm onSubmit={onSubmit} />
  } else {
    return(
      <Editable onClick={onClick}>
        Password
      </Editable>
    )
  }
}

export const ChangeLink = ({ plan }) => (
  <StyledChangeLink to={'/plans/from/' + plan.name.toLowerCase()}>
    Change my Plan
  </StyledChangeLink>
)

Settings.propTypes = {
  plan: PropTypes.object,
  business: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,

  fetchBusiness: PropTypes.func.isRequired,
  fetchPayment: PropTypes.func.isRequired,
  updatePayment: PropTypes.func.isRequired,
  deleteBusiness: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    business: state.business,
    plan: filterPlan(state.business),
    payment: state.payment,
    currentUser: state.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchBusiness,
    fetchPayment,
    updatePayment,
    deleteBusiness,
    getCurrentUser,
    updatePassword,
    updateUser,
  }, dispatch)
}

function filterPlan(business) {
  return plans.filter(plan => plan.name === business.plan)[0]
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)