import React from 'react'
import { Tag, Step, Container } from './style'

const Wordpress = ({ businessId, embedVersion }) => (
  <Container>
    <Step>
      First visit your wordpress sites admin page.
    </Step>

    <Step>
      Then click "Plugins" in the left side menu and "Add New".
    </Step>

    <Step>
      Search for "Tweeker" and then click "Install".
    </Step>

    <Step>
      After it installs click "Activate".
    </Step>

    <Step>
      Find the Tweeker plugin in your plugin list and click on "Settings".
    </Step>

    <Step>
      Copy and paste <Tag>{businessId}</Tag> for your "Business ID".
    </Step>

    <Step>
      Copy and paste <Tag>{embedVersion}</Tag> for your "Embed Version".
    </Step>

    <Step>
      Click "Save Changes"
    </Step>

    <Step>Once you’ve done that, click below to check if it’s installed correctly.</Step>
  </Container>
)
export default Wordpress