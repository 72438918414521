import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/url'

export const newUrl = (data) => dispatch => {
  return api
    .newUrl(data)
    .then(response => {
      dispatch({
        type: actionTypes.SAVE_URL_SUCCESS,
        payload: response,
      })
    })
    .catch(handleSaveUrlError)
}

const handleSaveUrlError = error => {
  alert(error.message)
}

export const deleteUrl = id => dispatch => {
  return api
    .deleteUrl(id)
    .then(response => {
      dispatch({
        type: actionTypes.DELETE_URL_SUCCESS,
        payload: response,
      })
    })
  .catch(handleDeleteUrlError)
}

const handleDeleteUrlError = error => {
  alert(error.message)
}