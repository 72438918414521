import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { firstUrl } from '../../library/helpers'

const Name = styled.h3`
  font: 600 14px 'Inter', sans-serif;
  color: #2c2c2c;
  margin: 20px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Url = styled.a`
  font: 500 14px 'Inter', sans-serif;
  color: #686868;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
`

const Meta = styled.div`
  font: 500 12px 'Inter', sans-serif;
  color: #979797;
`

const MetaSpan = styled.span`
  margin-left: 15px;
`

class TestDetail extends Component {
  render() {
    const { test } = this.props
    const url = firstUrl(test)

    return (
      <>
        <Name>{test.name}</Name>
        <Url href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </Url>
        <Meta>
          {test.variants.length} variants{' '}
          <MetaSpan>
            {test.page_views_count.toLocaleString()} page views
          </MetaSpan>
          <MetaSpan>
            {new Date(test.created_at).toLocaleDateString()}
          </MetaSpan>
        </Meta>
      </>
    )
  }
}

TestDetail.propTypes = {
  test: PropTypes.object.isRequired,
}

export default TestDetail
