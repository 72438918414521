import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CRISP_WEBSITE_ID } from '../../constants/helpers'

class Crisp extends Component {
  componentDidMount () {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID
    var d = document
    var s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props

    if (currentUser !== prevProps.currentUser && !!currentUser.email) {
      window.$crisp.push(['set', 'user:email', [currentUser.email]])
      window.$crisp.push(['set', 'user:nickname', [currentUser.name]])
    }
  }

  render() {
    return null
  }
}

Crisp.propTypes = {
  currentUser: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps)(Crisp)