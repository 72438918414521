import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import Plan from './plan'
import { HalfwidthHeader, SubTitle } from '../Style/headers'
import { Section } from '../Style/container'
import { Container, PricingGrid } from './style'
import plans from '../../constants/plans'
import { parseQuery } from '../../library/helpers'
import { shopifyPaymentUrl } from '../../library/helpers'
import { fetchBusiness } from '../../actions/business'

class Plans extends Component {
  componentDidMount() {
    this.props.fetchBusiness()
  }

  render() {
    const { shop, business } = this.props
    const shopUrl = shop || business.shopify

    return (
      <Container>
        <HalfwidthHeader>
          select a payment plan that suits your projects best. your first two
          weeks are on us, so you can see if tweeker fits your needs.
        </HalfwidthHeader>
        <Section>
          <PricingGrid>
            {plans.map((plan, index) =>
              <Plan
                key={index}
                plan={plan}
                cta="Select"
                url={setUrl(plan, shopUrl)}
              />
            )}
          </PricingGrid>
        </Section>
        <SubTitle>
          Need more than a million page views? Want to test unlimited domains?{' '}
          <Link to="/contact">Contact Us</Link>
        </SubTitle>
      </Container>
    )
  }
}

const setUrl = (plan, shopUrl) => {
  if (!!shopUrl) {
    return shopifyPaymentUrl(shopUrl, plan.name)
  } else {
    return `/payment/${plan.name.toLowerCase()}`
  }
}

Plans.propTypes = {
  shop: PropTypes.string,
  business: PropTypes.object.isRequired,

  fetchBusiness: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    shop: parseQuery(ownProps, 'shop'),
    business: state.business,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBusiness,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans)
