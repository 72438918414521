import callApi from '../'

export function fetchBusiness() {
  return callApi('business')
}

export function updateBusiness(body) {
  return callApi('business', 'PUT', body)
}

export function deleteBusiness() {
  return callApi('business', 'DELETE')
}