import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/business'
import { logOut } from '../auth'
import { redirect } from '../../library/redirect'

export const fetchBusiness = () => dispatch => {
  dispatch({ type: actionTypes.FETCH_START })

  return api
    .fetchBusiness()
    .then(response => {
      dispatch({
        type: actionTypes.FETCH_BUSINESS_SUCCESS,
        payload: response,
      })
    })
    .catch(handleError)
}

export const updateBusiness = (body, redirectPath = '/plans') => dispatch => {
  return api
    .updateBusiness(body)
    .then(response => {
      dispatch({
        type: actionTypes.UPDATE_BUSINESS_SUCCESS,
        payload: response
      })
      redirect(redirectPath, 'Shopify Store Connected.')
    })
    .catch(handleError)
}

export const deleteBusiness = () => dispatch => {
  return api
    .deleteBusiness()
    .then(response => {
      logOut('Your account has been deleted.')(dispatch)
    })
    .catch(error => handleError(error))
}


const handleError = (error) => {
  console.log(error)
}