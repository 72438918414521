import styled from 'styled-components'
import { Form } from 'formik'
import { SimpleField } from '../Form/input'
import { InlineButton } from '../Style/button'

export const StyledForm = styled(Form)`
  overflow: hidden;
  margin-top: 20px;
  padding-top: 5px;
`

export const StyledField = styled(SimpleField)`
  display: block;
  background: #f0f5f8;
  margin-bottom: 10px;
`

export const StyledButton = styled(InlineButton)`
  float: right;
`