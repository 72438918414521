import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/plan'
import { redirect } from '../../library/redirect'
import { handleError } from '../error'

export const fetchPlan = (data) => dispatch => {
  return api
    .fetchPlan(data)
    .then(response => {
      dispatch({
        type: actionTypes.FETCH_PLAN_SUCCESS,
        payload: response,
      })
    })
    .catch(error => console.log(error))
}

export const updatePlan = (data) => dispatch => {
  return api
    .updatePlan(data)
    .then(response => {
      dispatch({
        type: actionTypes.SAVE_PLAN_SUCCESS,
        payload: response,
      })

      redirect(
        '/settings',
        'Your plan has been successfully changed.'
      )
    })
    .catch(error => handleError(error, dispatch))
}