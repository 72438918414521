import React from 'react'
import { Form } from 'formik'
import Input from '../Form/input'
import { ReactComponent as BeakersSvg } from '../../images/beakers.svg'
import styled from 'styled-components'

export const Container = styled.div`
  width: 385px;
  margin: 0 auto;

  @media (max-width: 385px) {
    width: 100%;
  }
`

export const StyledForm = styled(Form)`
  margin-top: 25px;
`

const BeakerContainer = styled.div`
  text-align: center;
  margin-bottom: 15px;
`

export const Beakers = () => (
  <BeakerContainer>
    <BeakersSvg />
  </BeakerContainer>
)

export const Header = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`

export const AuthInput = styled(Input)`
  background: #f0f5f8;
`

export const Alternates = styled.ul`
  margin-top: 40px;
  color: #7e7e7e;
  text-align: center;
`

export const Alternate = styled.li`
  margin-bottom: 15px;

  a {
    font-weight: 600;
    color: #7e7e7e;
  }
`
