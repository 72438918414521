import styled from 'styled-components'

export const Title = styled.h2`
  color #454545;
  font: 600 19px 'Inter', sans-serif;
  margin-bottom: 40px;
  line-height: 1.4;
`

export const SubTitle = styled.h3`
  font-size: 14px;
  color: #525252;
`

export const HalfwidthHeader = styled(Title)`
  width: 70%;

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const SectionTitle = styled.h3`
  color: #4566a4;
  font: 600 13px 'Inter', sans-serif;
`