import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TimeAgo from 'timeago-react'
import { Link } from 'react-router-dom'
import ConversionRate from './conversionRate'
import { Button } from '../Style/button'

const width = '1200px'

const HeaderContainer = styled.div`
  margin-bottom: 40px;
`

const HeaderDataSection = styled.div`
  display: inline-block;
  margin-right: 65px;
  padding-right: 65px;
  border-right: 1px solid #cccccc;

  @media (max-width: ${width}) {
    display: block;
    width: 100%;
    border-right: none;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #cccccc;
  }
`

const Subheader = styled.h3`
  font: 600 13px 'Inter', sans-serif;
  color: #0072b1;
  margin-bottom: 20px;

  @media (max-width: ${width}) {
    margin-bottom: 10px;
  }
`

const HeaderData = styled.h2`
  font: 600 20px 'Inter', sans-serif;
  color: #5d5d5d;
  margin-bottom: 5px;
`

const SubData = styled.h4`
  font: 500 12px 'Inter', sans-serif;
  color: #989898;
`

const PageViewSubsection = styled.div`
  display: inline-block;

  :last-of-type {
    margin-left: 75px;
  }
`

const ButtonContainer = styled.div`
  float: right;
  position: relative;

  @media (max-width: ${width}) {
    float: none;
  }
`

const StyledButton = styled(Button)`
  position: relative;
  top: 15px;
`

const Trend = ({ rate }) => {
  if (rate > 0) {
    return 'increase'
  } else if (rate < 0) {
    return 'decrease'
  } else if (rate === 0) {
    return 'neutral'
  } else {
    return 'not enough data'
  }
}

class Header extends Component {
  render() {
    const { test, url } = this.props
    const date = new Date(test.created_at).toLocaleDateString()

    return (
      <HeaderContainer>
        <HeaderDataSection>
          <Subheader>Test Run Time</Subheader>
          <HeaderData>
            <TimeAgo datetime={test.created_at} />
          </HeaderData>
          <SubData>test created on {date}</SubData>
        </HeaderDataSection>
        <HeaderDataSection>
          <Subheader>Page Views</Subheader>
          <PageViewSubsection>
            <HeaderData>{test.page_views_count.toLocaleString()}</HeaderData>
            <SubData>total</SubData>
          </PageViewSubsection>
          <PageViewSubsection>
            <HeaderData>{test.uniques_count.toLocaleString()}</HeaderData>
            <SubData>unique</SubData>
          </PageViewSubsection>
        </HeaderDataSection>
        <HeaderDataSection>
          <Subheader>Conversion Rate</Subheader>
          <HeaderData>
            <ConversionRate rate={test.conversion_rate} />
          </HeaderData>
          <SubData>
            <Trend rate={test.conversion_rate} />
          </SubData>
        </HeaderDataSection>
        <ButtonContainer>
          <StyledButton as={Link} to={'/tests?url=' + url}>
            View Page in Editor
          </StyledButton>
        </ButtonContainer>
      </HeaderContainer>
    )
  }
}

Header.propTypes = {
  test: PropTypes.object.isRequired,
}

export default Header
