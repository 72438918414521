import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import noDataChart from '../../images/no-data-chart.png'
import flask from '../../images/flask.svg'
import { backgroundImage } from '../../library/style'

const Container = styled.div`
  margin-bottom: 45px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0px #d3dbe8;
  position: relative;
`

const Image = styled.img`
  width: 100%;
  display: block;
`

const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (max-width: 700px) {
    width: 90%;
  }
`

const Flask = styled.div`
  ${backgroundImage(flask, 75, 40)}
  margin: 0 auto;

  @media (max-width: 700px) {
    display: none;
  }
`

const Header = styled.h2`
  font: 600 22px 'Inter', sans-serif;
  color: #4c75c0;
  margin: 15px 0;

  @media (max-width: 700px) {
    font-size: 16px;
  }
`

const Explainer = styled.p`
  font: 500 16px 'Inter', sans-serif;
  color: #636363;
  line-height: 1.8;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 1.4;
  }
`

class Chart extends Component {
  render() {
    const { test, url } = this.props

    return(
      <Container>
        <Image src={noDataChart} />
        <Text>
          <Flask />
          <Header>{this.headerContent(test)}</Header>
          <Explainer>
            <ExplainerContent test={test} url={url} />
          </Explainer>
        </Text>
      </Container>
    )
  }

  headerContent(test) {
    if (test.variants.length < 1) {
      return 'You need to add variants!'
    } else if (test.goals.length < 1) {
      return 'You need to add goals!'
    } else {
      return 'your test has just begun!'
    }
  }
}

const ExplainerContent = ({ test, url }) => {
  if (test.variants.length < 1 || test.goals.length < 1) {
    return(
      <>
        please finish setting up your test first {' '}
        <Link to={url}>here</Link>.
        <br />
        then we can start showing you results.
      </>
    )
  } else {
    return(
      <>
        once your test has enough data, we’ll display a graph with your results.
        <br />
        we'll email you when there's something to see here.
      </>
    )
  }
}

Chart.propTypes = {
  test: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
}

export default Chart