import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'
import { mergeIntoArray } from '../helpers'

const variants = (state = initialState.variants, action) => {
  switch (action.type) {
    case actionTypes.SET_VARIANTS:
      return action.payload
    case actionTypes.MERGE_VARIANT:
      return mergeIntoArray(state, action.payload)
    case actionTypes.SAVE_VARIANT_SUCCESS:
      const removedTemp = state.filter(variant => variant.id !== undefined)
      return mergeIntoArray(removedTemp, action.payload)
    case actionTypes.DELETE_VARIANT_SUCCESS:
      return state.filter(variant => variant.id !== parseInt(action.payload))
    default:
      return state
  }
}

export default variants
