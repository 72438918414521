export default {
  goal: {
    selector: null,
    category: 'click',
  },

  header: {
    show: true,
    nav: true,
  },

  variants: [],
  goals: [],
  url: null,

  business: {
    id: null,
    embed_version: 'latest',
    installed: false,
    plan: '',
    paid: false,
    shopify: null,
  },

  currentUser: {
    jwt: null,
    email: null,
    name: null,
    loaded: false,
  },

  flash: {
    message: null,
    error: false,
  },

  test: {
    id: null,
    name: '',
    created_at: new Date().toLocaleString(),
    page_views_count: 0,
    uniques_count: 0,
    successes_count: 0,
    original_rate: 0,
    testers_rate: 0,
    conversion_rate: 0,
    variants: [],
    urls: [],
    embedded: false,
  },

  tests: [],

  payment: {
    name: '',
    address_country: 'United States',
    address_city: '',
    address_state: '',
    address_line1: '',
    address_line2: '',
    address_zip: '',
    brand: '',
    country: '',
    exp_month: '',
    exp_year: '',
    last4: '',
  },

  error: null,

  plan: {
    name: '',
    next_payment_date: new Date().toLocaleString(),
    amount: 0
  }
}
