import React from 'react'
import { Block, Tag, Step, Container, Header } from './style'

const Javascript = ({ businessId, embedVersion}) => (
  <Container>
    <Header>
      Use Tweeker as a Javascript ES6 Import.
    </Header>

    <Step>
      First install the module using <Tag>npm</Tag> or <Tag>yarn</Tag>.
    </Step>

    <Block>npm install tweeker-js</Block>

    <Step>
      Then, import the embed function
    </Step>

    <Block>
      {'import { embedTweeker } from "tweeker-js"'}
    </Block>

    <Step>
      Finally, run the function on every page in your app:
    </Step>

    <Block>
      {'embedTweeker({ businessId: "' + businessId + '", embedVersion: "' + embedVersion + '" })'}
    </Block>

    <Step>
      Make sure you include the argument object with <Tag>businessId</Tag> and <Tag>embedVersion</Tag> set like you see above.
    </Step>

    <Step>Once you’ve done that, click below to check if it’s installed correctly.</Step>
  </Container>
)

export default Javascript