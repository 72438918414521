import * as actionTypes from '../../constants/actionTypes'

export const setFlash = (data) => dispatch => {
  dispatch({
    type: actionTypes.SET_FLASH,
    payload: data
  })
}

export const getFlash = () => dispatch => {
  dispatch({
    type: actionTypes.GET_FLASH,
  })
}

export const clearFlash = () => dispatch => {
  dispatch({
    type: actionTypes.CLEAR_FLASH,
  })
}
