import callApi from '../'

export function fetchPayment() {
  return callApi('payment')
}

export function savePayment(data) {
  return callApi('payment', 'POST', data)
}

export function updatePayment(data) {
  return callApi('payment', 'PUT', data)
}