import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const goal = (state = initialState.goal, action) => {
  switch (action.type) {
    case actionTypes.SET_GOAL:
    case actionTypes.SAVE_GOAL_SUCCESS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default goal
