import * as actionTypes from '../../constants/actionTypes'
import * as storage from '../../library/storage'
import * as helpers from '../../constants/helpers'
import initialState from '../initialState'

const flash = (state = initialState.flash, action) => {
  switch (action.type) {
    case actionTypes.GET_FLASH:
      return storage.get(helpers.FLASH_KEY) || initialState.flash
    case actionTypes.CLEAR_FLASH:
      return initialState.flash
    case actionTypes.SET_FLASH:
      return action.payload
    default:
      return state
  }
}

export default flash
