import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/payment'
import { redirect } from '../../library/redirect'
import { handleError } from '../error'

export const fetchPayment = () => dispatch => {
  return api
    .fetchPayment()
    .then(response => {
      dispatch({
        type: actionTypes.FETCH_PAYMENT_SUCCESS,
        payload: response,
      })
    })
    .catch(error => console.log(error))
}

export const savePayment = (data, afterPayPath) => dispatch => {
  return api
    .savePayment(data)
    .then(response => {
      dispatch({
        type: actionTypes.SAVE_PAYMENT_SUCCESS,
        payload: response,
      })

      redirect(
        afterPayPath,
        'Great! Your credit card info has been saved securely.'
      )
    })
    .catch(error => handleError(error, dispatch))
}

export const updatePayment = (data) => dispatch => {
  return api
    .updatePayment(data)
    .then(response => {
      dispatch({
        type: actionTypes.SAVE_PAYMENT_SUCCESS,
        payload: response,
      })

      redirect(
        '/settings',
        'Great! Your new credit card info has been saved securely.'
      )
    })
    .catch(error => handleError(error, dispatch))
}