import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Editable from './editable'
import { SectionTitle } from '../Style/headers'

const Strong = styled.span`
  font-weight: bold;
`

class ExistingPayment extends Component {
  render() {
    const { payment, onClick } = this.props

    return(
      <>
        <SectionTitle>My Payment Method</SectionTitle>
        <Editable onClick={onClick}>
          <Strong>{payment.brand}</Strong>
          {' '} ending in {' '}
          <Strong>{payment.last4}</Strong>
        </Editable>
      </>
    )
  }
}

ExistingPayment.propTypes = {
  payment: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ExistingPayment