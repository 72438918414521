import * as actionTypes from '../../constants/actionTypes'
import * as helpers from '../../constants/helpers'
import initialState from '../../reducers/initialState'
import * as api from '../../api/auth'
import * as storage from '../../library/storage'
import { redirect } from '../../library/redirect'
import { handleError } from '../error'

export const signUp = body => dispatch => {
  dispatch({ type: actionTypes.FETCH_START })

  const redirectPath = !!body.shopify ? `/tests/new?value=${body.shopify}` : '/tests/new'

  return api
    .signUp(body)
    .then(response => {
      afterCreate(dispatch, actionTypes.CREATE_USER_SUCCESS, response)
      redirect(redirectPath)
    })
    .catch(error => handleError(error, dispatch))
}

export const logIn = body => dispatch => {
  dispatch({ type: actionTypes.FETCH_START })

  return api
    .logIn(body)
    .then(response => {
      afterCreate(dispatch, actionTypes.CREATE_SESSION_SUCCESS, response)
      redirect('/', 'You are now logged in!')
    })
    .catch(error => handleError(error, dispatch))
}

export const getCurrentUser = () => dispatch => {
  dispatch({
    type: actionTypes.GET_CURRENT_USER,
  })
}

export const logOut = (message = 'You are now logged out!') => dispatch => {
  dispatch({
    type: actionTypes.LOG_OUT,
  })

  resetAuthKey()

  redirect('/log-in', message)
}

export const resetAuthKey = () => {
  storage.set(helpers.AUTH_KEY, initialState.currentUser)
}

export const updateUser = (body) => dispatch => {
  return api
    .updateUser(body)
    .then(response => {
      afterCreate(dispatch, actionTypes.UPDATE_USER_SUCCESS, response)
      redirect('/settings', 'User account updated.')
    })
    .catch(error => handleError(error, dispatch))
}

const afterCreate = (dispatch, type, response) => {
  dispatch({
    type: type,
    response,
  })

  storage.set(helpers.AUTH_KEY, response)
}
