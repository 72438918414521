import * as constants from '../constants/helpers'
import * as storage from '../library/storage'
import { redirect } from '../library/redirect'
import { resetAuthKey } from '../actions/auth'

export default async function callApi(path, method = 'GET', body = undefined) {
  const url = constants.API_ROOT_URL + '/' + path

  if (!!body && !!body.token) {
    console.warn(
      "Don't send 'token' as a key, it will confuse the authentication gem."
    )
  }

  const response = await fetch(url, {
    method: method,
    headers: setHeaders(),
    body: JSON.stringify(body),
  })

  if (response.status === 204) return ''
  if (response.status === 401) {
    resetAuthKey()
    const path = encodeURIComponent(window.location.pathname)
    redirect(
      '/log-in?redirect=' + path,
      'You must be logged in to view this page.',
      true
    )
    return false
  }

  if (response.status === 402) {
    redirect('/plans', 'Please Sign up for a plan first.', true)
    return false
  }

  const json = await response.json()

  return response.ok ? json : Promise.reject(json)
}

const setHeaders = () => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (!!storage.get(constants.AUTH_KEY)) {
    headers['Authorization'] = 'Bearer ' + storage.get(constants.AUTH_KEY).jwt
  }

  return headers
}
