import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'
import { mergeIntoArray } from '../helpers'

const goals = (state = initialState.goals, action) => {
  switch (action.type) {
    case actionTypes.SET_GOALS:
      return action.payload
    case actionTypes.MERGE_GOAL:
      return mergeIntoArray(state, action.payload)
    case actionTypes.SAVE_GOAL_SUCCESS:
      const removedTemp = state.filter(goal => goal.id !== undefined)
      return mergeIntoArray(removedTemp, action.payload)
    case actionTypes.DELETE_GOAL_SUCCESS:
      return state.filter(goal => goal.id !== parseInt(action.payload))
    default:
      return state
  }
}

export default goals
