import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const business = (state = initialState.business, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BUSINESS_SUCCESS:
      return setBusiness(state, action.payload)
    default:
      return state
  }
}

const setBusiness = (state, data) => {
  return { ...state, ...data }
}

export default business
