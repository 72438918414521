export const OVERLAY_SHOW = 'OVERLAY_SHOW'
export const OVERLAY_CLOSE = 'OVERLAY_CLOSE'
export const SET_VARIANTS = 'SET_VARIANTS'
export const MERGE_VARIANT = 'MERGE_VARIANT'
export const SAVE_VARIANT_SUCCESS = 'SAVE_VARIANT_SUCCESS'
export const DELETE_VARIANT_SUCCESS = 'DELETE_VARIANT_SUCCESS'
export const FETCH_TEST_SUCCESS = 'FETCH_TEST_SUCCESS'
export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS'
export const FETCH_TESTS_SUCCESS = 'FETCH_TESTS_SUCCESS'
export const SET_GOAL = 'SET_GOAL'
export const SAVE_GOAL_SUCCESS = 'SAVE_GOAL_SUCCESS'
export const SET_GOALS = 'SET_GOALS'
export const MERGE_GOAL = 'MERGE_GOAL'
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const LOG_OUT = 'LOG_OUT'
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS'
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS'
export const TOGGLE_NAV = 'TOGGLE_NAV'
export const CLOSE_NAV = 'CLOSE_NAV'
export const GET_FLASH = 'GET_FLASH'
export const CLEAR_FLASH = 'CLEAR_FLASH'
export const SET_FLASH = 'SET_FLASH'
export const SAVE_PAYMENT_SUCCESS = 'SAVE_PAYMENT_SUCCESS'
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS'
export const FETCH_START = 'FETCH_START'
export const FETCH_ERROR = 'FETCH_ERROR'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const SET_HEADER = 'SET_HEADER'
export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS'
export const SAVE_PLAN_SUCCESS = 'SAVE_PLAN_SUCCESS'
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS'
export const SAVE_URL_SUCCESS = 'SAVE_URL_SUCCESS'
export const DELETE_URL_SUCCESS = 'DELETE_URL_SUCCESS'
export const SET_TEST = 'SET_TEST'