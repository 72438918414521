import styled from 'styled-components'

export const Container = styled.div`
  width: 1200px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 95%;
  }
`

export const Section = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 40px;
  position: relative;
`
