import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import { fetchBusiness, updateBusiness } from '../../actions/business'
import { parseQuery } from '../../library/helpers'
import { SHOPIFY_PLUGIN_URL } from '../../constants/helpers'

class Shopify extends Component {
  componentDidUpdate(prevProps) {
    const { currentUser } = this.props
    const { currentUser: prevUser } = prevProps

    if (currentUser.loaded && !prevUser.loaded && !!currentUser.jwt) {
      this.props.fetchBusiness()
    }
  }

  render() {
    const { currentUser, shop, business, updateBusiness } = this.props
    if (!currentUser.loaded) { return null }

    if (!!currentUser.jwt) {
      // wait until the business is returned
      if (!business.id) { return null }

      const redirect = business.paid ?
      `${SHOPIFY_PLUGIN_URL}/embed?shop=${shop}&business_id=${business.id}&embed_version=${business.embed_version}` :
      '/plans?shop=' + shop
      updateBusiness({ shopify: shop }, redirect)
      return null
    } else {
      return <Redirect to={'/sign-up?shop=' + shop} />
    }
  }
}

Shopify.propTypes = {
  shop: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,

  fetchBusiness: PropTypes.func.isRequired,
  updateBusiness: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    shop: parseQuery(ownProps, 'shop'),
    business: state.business,
    currentUser: state.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchBusiness, updateBusiness }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Shopify)