import React from 'react'
import styled from 'styled-components'

const EditableContainer = styled.div`
  color: #525252;
  margin: 25px 0;
  border-bottom: 1px solid #dedede;
  padding-bottom: 5px;
`

const Change = styled.a`
  color: #4566a4;
  font: 600 13px 'Inter', sans-serif;
  float: right;
  cursor: pointer;
`

const Editable = ({ onClick, children }) => (
  <EditableContainer>
    {children}
    {onClick &&
      <Change onClick={onClick}>Change</Change>
    }
  </EditableContainer>
)

export default Editable