import * as api from '../../api/passwords'
import { redirect } from '../../library/redirect'
import { handleError } from '../error'

export const forgotPassword = body => dispatch => {
  return api
    .forgotPassword(body)
    .then(() =>
      redirect('/log-in', 'Check your email!')
    )
    .catch(error => handleError(error, dispatch))
}

export const newPassword = body => dispatch => {
  return api
    .newPassword(body)
    .then(() =>
      redirect('/log-in', 'Password Reset. Please Log In.')
    )
    .catch(error => handleError(error, dispatch))
}

export const updatePassword = body => dispatch => {
  return api
    .updatePassword(body)
    .then(() =>
      redirect('/settings', 'Your password has been updated.')
    )
    .catch(error => handleError(error, dispatch))
}