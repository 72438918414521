import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Menu from './menu'
import placeholder from '../../images/placeholder.png'
import { firstUrl } from '../../library/helpers'

const width = '800px'

const ImageContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px 0px #d3dbe8;
  position: relative;

  :hover > div {
    opacity: 1;
    visibility: visible;
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(35, 35, 35, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;

  @media (max-width: ${width}) {
    visibility: visible;
    opacity: 1;
  }
`

const OverlayLink = styled(Link)`
  font: 600 12px 'Inter', sans-serif;
  color: #4c75c0;
  background: #fff;
  text-decoration: none;
  padding: 10px 25px;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Image = styled.img`
  width: 100%;
  display: block;
`

class TestImage extends Component {

  render() {
    const { test, business } = this.props
    const url = firstUrl(test)
    const link = `/tests/${test.id}`
    const image = `https://uploads.tweeker.io/${business.id}/${test.id}.png`

    return(
      <ImageContainer>
        <ImageOverlay>
          <Menu test={test} url={url} />
          <OverlayLink to={link}>view test results</OverlayLink>
        </ImageOverlay>
        <Image src={image} onError={this.setPlaceholder} />
      </ImageContainer>
    )
  }

  setPlaceholder(event) {
    event.target.src = placeholder
  }
}

TestImage.propTypes = {
  test: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
}

export default TestImage