import React from 'react'
import { Block, Tag, Step, Container } from './style'

const ReactEmbed = ({ businessId, embedVersion }) => (
  <Container>
    <Step>
      First install the component using <Tag>npm</Tag> or <Tag>yarn</Tag>.
    </Step>

    <Block>npm install react-tweeker-js</Block>

    <Step>
      Then, import Component into your App
    </Step>

    <Block>{appCode({ businessId, embedVersion })}</Block>

    <Step>
      Make sure you include the argument object with <Tag>businessId</Tag> and <Tag>embedVersion</Tag> set like you see above.
    </Step>

    <Step>
      Also make sure this code will be run on every page you have variants you want to test.
    </Step>

    <Step>Once you’ve done that, click below to check if it’s installed correctly.</Step>
  </Container>
)

const appCode = ({ businessId, embedVersion }) => (
  `import React from "react"
import ReactDOM from "react-dom"
import TweekerEmbed from "react-tweeker-js"

const App = () => (
  <>
    <TweekerEmbed businessId="${businessId}" embedVersion="${embedVersion}" />

    {...your other components}
  </>
);

ReactDOM.render(<App />, document.body)`
)

export default ReactEmbed