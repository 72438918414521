import styled from 'styled-components'
import { SubTitle } from '../../Style/headers'

export const Tag = styled.code`
  white-space: pre-wrap;
  font-family: Courier, monospace;
  padding: 7px;
  margin: 0 5px;
  display: inline;
  color: #999;
  font-size: 13px;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
`

export const Block = styled(Tag)`
  display: block;
  padding: 15px;
  border-radius: 10px;
`

export const Step = styled(SubTitle)`
  margin: 15px 0;
`

export const Header = styled(SubTitle)`
  font-weight: 600;
`

export const Container = styled.div`
  margin: 40px 0;
`