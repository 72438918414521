import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './reset.css'
import './fonts.css'
import './index.css'
import App from './components/App'
import store from './store'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c2219e85fd3941609136bf719700bf3a@sentry.io/1836914',
  })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
