import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/variant'

export const saveVariant = (body, url) => dispatch => {
  mergeVariant(body)(dispatch)
  return api
    .saveVariant(body, url)
    .then(response => handleCreateVariantResponse(response)(dispatch))
}

export const saveOriginal = (data, url) => dispatch => {
  mergeVariant(data.original)(dispatch)
  mergeVariant(data.variant)(dispatch)

  return api.saveVariant(data.original, url).then(response => {
    handleCreateVariantResponse(response)(dispatch)

    api
      .saveVariant(data.variant, url)
      .then(nextResponse => handleCreateVariantResponse(nextResponse)(dispatch))
  })
}

const handleCreateVariantResponse = response => dispatch => {
  dispatch({
    type: actionTypes.SAVE_VARIANT_SUCCESS,
    payload: response,
  })
}

export const mergeVariant = variant => dispatch => {
  dispatch({
    type: actionTypes.MERGE_VARIANT,
    payload: variant,
  })
}

export const deleteVariant = id => dispatch => {
  return api.deleteVariant(id).then(
    dispatch({
      type: actionTypes.DELETE_VARIANT_SUCCESS,
      payload: id,
    })
  )
}
