import * as actionTypes from '../../constants/actionTypes'

export function handleError(error, dispatch) {
  dispatch({
    type: actionTypes.FETCH_ERROR,
    payload: error.message || error.error,
  })
}

export function setError(error) {
  return {
    type: actionTypes.FETCH_ERROR,
    payload: error
  }
}
