import React, { Component } from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`
  position: absolute;
  transition: all 150ms ease-in-out;
  left: 15px;
  top: 15px;
  font-size: 16px;
  color: #9fa6aa;
  pointer-events: none;
`

export const ShownLabel = styled.label`
  display: block;
  color: #4566a4;
  font: 600 13px 'Inter', 'sans-serif';
  margin-bottom: 7px;
`

class Label extends Component {
  render() {
    const { label, name, showLabel } = this.props

    if (!label) {
      return null
    }

    if (showLabel) {
      return <ShownLabel htmlFor={name}>{label}</ShownLabel>
    } else {
      return <StyledLabel htmlFor={name}>{label}</StyledLabel>
    }
  }
}

export default Label
