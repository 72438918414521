import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const plan = (state = initialState.plan, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PLAN_SUCCESS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default plan
