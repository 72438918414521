import { css } from 'styled-components'

export const TooltipCss = css`
  position: relative;

  :after {
    position: absolute;
    visibility: hidden;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transform: translate3d(0, 0, 0);
    pointer-events: none;

    z-index: 1000;
    padding: 15px;
    width: 200px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    font-size: 12px;
    line-height: 1.7;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
  }

  :hover:after {
    visibility: visible;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
`
