import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import Plan from './plan'
import ExistingPayment from '../Settings/existingPayment'
import { Title } from '../Style/headers'
import { Section } from '../Style/container'
import { Container } from './style'
import plans from '../../constants/plans'
import { fetchPlan, updatePlan } from '../../actions/plan'
import { fetchPayment } from '../../actions/payment'
import { fetchBusiness } from '../../actions/business'
import { shopifyPaymentUrl } from '../../library/helpers'
import { backgroundImage } from '../../library/style'
import { InlineButton } from '../Style/button'
import back from '../../images/back.svg'

const Highlight = styled.span`
  color: #4c75c0;
`

const SummaryContainer = styled.div`
  text-align: center;
  height: 100%;
  vertical-align: middle;
`

const PlanSection = styled(Section)`
  display: inline-block;
`

const Arrow = styled.div`
  display: inline-block;
  ${backgroundImage(back, 9, 13)};
  transform: rotate(-90deg);
  margin: 17% 25px 0;
  vertical-align: top;

  @media(max-width: 650px) {
    display: none;
  }
`

const containerStyles = css`
  text-align: center;
  margin: 0 0 50px;
`

const HiddenForm = styled(Form)`
  ${containerStyles}
`

const ButtonContainer = styled.div`
  ${containerStyles}
`

class ConfirmPlan extends Component {
  componentDidMount() {
    const { fetchPlan, fetchPayment, newPlan, fetchBusiness } = this.props

    fetchPlan({ plan: newPlan.name })
    fetchPayment()
    fetchBusiness()
  }

  render() {
    const { newPlan, oldPlan, plan, payment, updatePlan, business } = this.props

    return(
      <Container>
        <SummaryContainer>
          <ChangeSummary plan={plan} newPlan={newPlan} />
          <PlanSection>
            <Title>From</Title>
            <Plan plan={oldPlan} showButton={false} />
          </PlanSection>
          <Arrow>></Arrow>
          <PlanSection>
            <Title>To</Title>
            <Plan plan={newPlan} showButton={false} />
          </PlanSection>
        </SummaryContainer>
        <ChangeButton {...{business, newPlan, updatePlan}} />
        {!business.shopify &&
          <Section>
            <ExistingPayment payment={payment} onClick={this.paymentForm} />
          </Section>
        }
      </Container>
    )
  }

  paymentForm() {
    window.location = '/settings?showPaymentForm=true'
  }
}

const ChangeSummary = ({ plan, newPlan }) => {
  const amount = Math.abs(Math.floor(plan.amount / 100))
  const date = new Date(plan.next_payment_date).toLocaleDateString()

  if (plan.amount < 0) {
    return(
      <Title>
        we'll apply a {' '}
        <Highlight>${amount}</Highlight>{' '}
        credit to your account and you'll be charged {' '}
        <Highlight>${newPlan.cost}</Highlight>{' '}
        starting on {' '}
        <Highlight>{date}</Highlight>.
      </Title>
    )
  } else {
    return(
      <Title>
        you will be charged {' '}
        <Highlight>${amount}</Highlight>{' '}
        now and {' '}
        <Highlight>${newPlan.cost}</Highlight>{' '}
        on your future invoices, beginning on {' '}
        <Highlight>{date}</Highlight>.
      </Title>
    )
  }
}

const ChangeButton = ({business, newPlan, updatePlan}) => {
  if (business.shopify) {
    return <ButtonContainer>
      <InlineButton as='a' href={shopifyPaymentUrl(business.shopify, newPlan.name)}>change my plan</InlineButton>
    </ButtonContainer>
  } else {
    return <PlanChangeForm {...{newPlan, updatePlan}} />
  }
}

const PlanChangeForm = ({ newPlan, updatePlan }) => (
  <Formik
    initialValues={{plan: newPlan.name}}
    validationSchema={Yup.object({
      plan: Yup.string()
        .required('Required')
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      updatePlan(values)
    }}
  >
    {form => (
      <HiddenForm>
        <Field type='hidden' name='plan' />
        <InlineButton type="submit" value="change my plan" disabled={form.isSubmitting} />
      </HiddenForm>
    )}
  </Formik>
)
ConfirmPlan.propTypes = {
  plan: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  newPlan: PropTypes.object.isRequired,
  oldPlan: PropTypes.object.isRequired,

  fetchPlan: PropTypes.func.isRequired,
  fetchPayment: PropTypes.func.isRequired,
  updatePlan: PropTypes.func.isRequired,
  fetchBusiness: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    plan: state.plan,
    payment: state.payment,
    business: state.business,
    newPlan: filterPlan(ownProps, 'newPlan'),
    oldPlan: filterPlan(ownProps, 'oldPlan'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPlan,
    fetchPayment,
    updatePlan,
    fetchBusiness,
  }, dispatch)
}



function filterPlan(props, key) {
  const param = props.match.params[key]
  return plans.filter(plan => plan.name.toLowerCase() === param)[0]
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPlan)