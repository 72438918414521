import React from 'react'
import styled, { keyframes } from 'styled-components'
import { InlineButton } from '../Style/button'
import { Link } from 'react-router-dom'

const ellipsis = keyframes`
  to {
    width: 1em;
  }
`

const CheckingButton = styled(InlineButton)`
  padding: 17px calc(30px + 1em) 17px 30px;
  background: #a0b7e2;
  cursor: not-allowed;

  :hover {
    background: #a0b7e2;
  }

  :after {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsis} steps(4, end) 900ms infinite;
    content: '\u2026';
    width: 0px;
  }
`

const CompleteButton = styled(InlineButton)`
  :before {
    content: '✓';
    margin-right: 5px;
  }
`

const EmbedCheckerButton = ({ onClick, status }) => {
  if (status === 'checking') {
    return <CheckingButton as="div">checking your installation</CheckingButton>
  } else if (status === 'installed') {
    return (
      <CompleteButton as={Link} to="/plans">
        installation successful! continue
      </CompleteButton>
    )
  } else {
    return (
      <InlineButton as="div" onClick={onClick}>
        check my embed code installation
      </InlineButton>
    )
  }
}

export default EmbedCheckerButton
