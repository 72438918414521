import styled from 'styled-components'

export const Button = styled.input`
  transition: background 0.2s ease-in-out;
  appearance: none;
  border: none;
  outline: none;
  font: 600 15px 'Inter', sans-serif;
  background: #4c75c0;
  color: white;
  border-radius: 5px;
  display: block;
  width: 100%;
  cursor: pointer;
  line-height: 1;
  padding: 17px 30px;
  text-align: center;
  text-decoration: none;

  :hover {
    background: #476bae;
  }

  :disabled {
    background: #c9d5ec;
    cursor: not-allowed;
  }
`

export const InlineButton = styled(Button)`
  display: inline-block;
  width: auto;
`

export const SmallButton = styled(InlineButton)`
  font-size: 12px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 3px;
`
