export default [
  {
    name: 'Individual',
    cost: 15,
    views: '10k',
    features: ['Unlimited Pages', '1 Domain', '1 User Account'],
    id: process.env.REACT_APP_PLAN_ID_INDIVIDUAL,
  },
  {
    name: 'Business',
    cost: 45,
    views: '100k',
    features: ['Unlimited Pages', '1 Domain', 'Unlimited User Accounts'],
    id: process.env.REACT_APP_PLAN_ID_BUSINESS,
  },
  {
    name: 'Enterprise',
    cost: 150,
    views: '1M',
    features: ['Unlimited Pages', '3 Domains', 'Unlimited User Accounts'],
    id: process.env.REACT_APP_PLAN_ID_ENTERPRISE,
  },
]
