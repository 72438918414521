import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PasswordFields from '../Auth/password_fields'
import { StyledForm, StyledButton } from './styles'
import { AuthInput } from '../Style/auth'

const PasswordForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      old_password:'',
      password: '',
      password_confirmation: '',
    }}
    validationSchema={Yup.object({
      old_password: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters'),
      password: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters'),
      password_confirmation: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      onSubmit(values)
    }}
  >
    {form => (
      <StyledForm>
        <AuthInput
          type="password"
          label="Current Password"
          name="old_password"
          autoComplete="new-password"
          autoFocus={true}
        />
        <PasswordFields label='New Password' />
        <StyledButton type="submit" value="update password" disabled={form.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)

export default PasswordForm