import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { forgotPassword } from '../../actions/passwords'
import Auth from '../Auth'
import Error from '../Error'
import {
  StyledForm,
  Header,
  AuthInput,
  Alternates,
  Alternate,
} from '../Style/auth'
import { Button } from '../Style/button'

class ForgotPassword extends Component {
  render() {
    return (
      <Auth>
        <Header>forgot your tweeker password?</Header>
        <Error />
        <ForgotPasswordForm forgotPassword={this.props.forgotPassword} />
        <Alternates>
          <Alternate>
            already have an account? <Link to="/log-in">log in</Link>.
          </Alternate>
          <Alternate>
            no account? <Link to="/sign-up">sign up</Link>.
          </Alternate>
        </Alternates>
      </Auth>
    )
  }
}

const ForgotPasswordForm = ({ forgotPassword }) => (
  <Formik
    initialValues={{ email: '' }}
    validationSchema={Yup.object({
      email: Yup.string()
        .email('Must be a valid email address')
        .required('Required')
        .max(255)
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      forgotPassword(values)
    }}
  >
    {form => (
      <StyledForm>
        <AuthInput
          type="email"
          label="Email Address"
          name="email"
          autoComplete="username"
        />
        <Button type="submit" value="send password reset email" disabled={form.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)

function mapDispatchToProps(dispatch) {
  return {
    forgotPassword: bindActionCreators(forgotPassword, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(ForgotPassword)
