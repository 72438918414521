import React from 'react'
import styled, { css } from 'styled-components'
import { backgroundImage } from '../../library/style'
import positive from '../../images/positive.svg'
import negative from '../../images/negative.svg'
import neutral from '../../images/neutral.svg'

const SharedStyles = css`
  white-space: nowrap;

  :before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
  }
`

const Positive = styled.div`
  color: #55c4b8;
  ${SharedStyles}

  :before {
    ${backgroundImage(positive, 22, 22)}
  }
`

const Negative = styled.div`
  color #f97777;
  ${SharedStyles}

  :before {
    ${backgroundImage(negative, 22, 22)}
  }
`

const Neutral = styled.div`
  color #7a7a7a;
  ${SharedStyles}

  :before {
    ${backgroundImage(neutral, 22, 22)}
  }
`

const ConversionRate = ({ rate, displayRate }) => {
  const toDisplay = !!displayRate ? displayRate : rate

  if (rate > 0) {
    return <Positive>{toDisplay}%</Positive>
  } else if (rate < 0) {
    return <Negative>{toDisplay}%</Negative>
  } else if (rate === 0) {
    return <Neutral>{displayRate || 0}%</Neutral>
  } else {
    return <Neutral>N/A</Neutral>
  }
}

export default ConversionRate
