import React, { Component } from 'react'

export function requiresEmbed(WrappedComponent) {
  return class extends Component {
    componentDidUpdate() {
      const { business } = this.props
      const message = `
        Please complete your
        <a href='/embed'>Tweeker script setup</a>.
        You must do this before running tests and seeing results.
      `

      if (!!business.id && !business.installed) {
        this.props.setFlash({
          message,
          error: true
        })
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}