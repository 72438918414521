import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import UrlForm from '../UrlForm'
import { Title } from '../Style/headers'
import { parseQuery } from '../../library/helpers'

const width = '700px'

const Container = styled.div`
  width: ${width};
  margin: 30vh auto 0;

  @media (max-width: ${width}) {
    width: 95%;
    margin: 15vh auto 0;
  }
`

const CenterTitle = styled(Title)`
  text-align: center;
  width: 90%;
  margin: 0 auto 40px;
  line-height: 2;
`

class Url extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  render() {
    return(
      <Container>
        <CenterTitle>
          Great!
          <br />
          Enter the URL of the website you will use Tweeker on.
        </CenterTitle>
        <UrlForm
          onSubmit={this.onSubmit}
          submit='Next'
          placeholder='https://www.example.com'
          value={this.props.value}
        />
      </Container>
    )
  }

  onSubmit(values) {
    this.props.history.push('/tests?url=' + values.url)
  }
}

Url.propTypes = {
  value: PropTypes.string,
  history: PropTypes.object.isRequired,
}

function mapStateToProps(_, ownProps) {
  return {
    value: parseQuery(ownProps, 'value'),
    history: ownProps.history,
  }
}

export default connect(mapStateToProps)(withRouter(Url))