import callApi from '../'

export function forgotPassword(body) {
  return callApi('passwords/forgot', 'POST', body)
}

export function newPassword(body) {
  return callApi('passwords', 'POST', body)
}

export function updatePassword(body) {
  return callApi('passwords', 'PUT', body)
}