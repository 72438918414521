import React, { Component } from 'react'
import { AuthInput } from '../Style/auth'

class PasswordFields extends Component {
  render() {
    const label = this.props.label || 'Password'
    return (
      <>
        <AuthInput
          type="password"
          label={label}
          name="password"
          autoComplete="new-password"
        />
        <AuthInput
          type="password"
          label="Password Confirmation"
          name="password_confirmation"
          autoComplete="new-password"
        />
      </>
    )
  }
}

export default PasswordFields
