import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ConversionRate from './conversionRate'
import Label from '@bit/goddamnyouryan.tweeker.label'
import { backgroundImage } from '../../library/style'
import crown from '../../images/crown.svg'

const width = '1000px'
const radius = '10px'
const cellPadding = '20px 30px 17px'

const TableContainer = styled.table`
  width: 100%;
  margin-bottom: 50px;

  @media (max-width: ${width}) {
    display: block;
  }
`

const Header = styled.thead`
  font: 600 12px 'Inter', sans-serif;
  color: #3d3d3d;

  @media (max-width: ${width}) {
    display: none;
  }
`

const HeaderCell = styled.th`
  padding: ${cellPadding};
  text-align: left;
  white-space: nowrap;
`

const Body = styled.tbody`
  font: 500 14px 'Inter', sans-serif;
  color: #7a7a7a;
  background: #fff;
  box-shadow: 0 0 5px 0px #d3dbe8;
  border-radius: 10px;

  tr:last-child td:first-child {
    border-bottom-left-radius: ${radius};
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: ${radius};
  }

  tr:first-child td:first-child {
    border-top-left-radius: ${radius};
  }

  tr:first-child td:last-child {
    border-top-right-radius: ${radius};
  }

  @media (max-width: ${width}) {
    display: block;
  }
`

const BodyRow = styled.tr`
  border-bottom: 1px solid #f0f0f0;
  background: ${props => (props.best ? '#f5fffd' : '#ffffff')}

  :last-of-type {
    border-bottom: none;
  }

  @media (max-width: ${width}) {
    display: block;
    padding: 30px;
  }
`

const BodyCell = styled.td`
  padding: ${cellPadding};

  @media (max-width: ${width}) {
    display: block;
    padding: 0;
    margin-bottom: 10px;
    width: 100%;
  }
`

const TableLabel = styled(BodyCell)`
  white-space: nowrap;
  width: 10%;
`

const VariantText = styled(BodyCell)`
  width: 50%
`

const VariantPageViews = styled(BodyCell)`
  width: 10%;

  @media (max-width: ${width}) {

    :after {
      content: ' page views';
    }
  }
`

const VariantConversionRate = styled(BodyCell)`
  width: 15%;
`

const BestCell = styled(BodyCell)`
  width: 15%;
`

const BestAward = styled.h4`
  font: 600 11px 'Inter', sans-serif;
  background-color: #55c4b8;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  padding: 10px 20px;
  white-space: nowrap;

  :before {
    ${backgroundImage(crown, 11, 14)}
    content: '';
    display: inline-block;
    margin-right: 7px;
    position: relative;
    top: 1px;
  }
`

const Table = ({ test }) => {
  const grouped = test.variants.reduce((acc, variant) => {
    acc[variant.selector] = acc[variant.selector] || []
    acc[variant.selector].push(variant)
    return acc
  }, Object.create(null))
  const highestConversion = Math.max(
    ...test.variants.map(variant => variant.conversion_rate)
  )

  return (
    <>
      {Object.values(grouped)
        .map((array, index) => {
          const original = array.filter(variant => variant.original)[0]
          const variants = array.filter(variant => !variant.original)

          return(
            <TableContainer key={index}>
              {index === 0 &&
                <TableHeader />
              }
              <Body>
                <SelectorResults
                  original={original}
                  variants={variants}
                  highestConversion={highestConversion}
                />
              </Body>
            </TableContainer>
          )
        })}
    </>
  )
}

const TableHeader = () => (
  <Header>
    <tr>
      <HeaderCell>variant</HeaderCell>
      <HeaderCell>variant text</HeaderCell>
      <HeaderCell>page views</HeaderCell>
      <HeaderCell>conversion rate</HeaderCell>
      <HeaderCell>&nbsp;</HeaderCell>
    </tr>
  </Header>
)

const SelectorResults = ({ original, variants, highestConversion }) => {
  return(
    <>
      <ListItem
        object={original}
        position={0}
        rate={0}
        displayRate={original.conversion_rate}
        best={
          highestConversion === original.conversion_rate &&
          highestConversion !== 0
        }
      />
      {variants.map((variant, index) => (
        <ListItem
          key={variant.id}
          object={variant}
          position={index + 1}
          rate={variant.conversion_rate - original.conversion_rate}
          displayRate={variant.conversion_rate}
          best={
            highestConversion === variant.conversion_rate &&
            highestConversion !== 0
          }
        />
      ))}
    </>
  )
}

const ListItem = ({ object, position, rate, displayRate, best }) => (
  <BodyRow best={best}>
    <TableLabel>
      <Label position={position} />
    </TableLabel>
    <VariantText>{object.text}</VariantText>
    <VariantPageViews>{object.page_views_count.toLocaleString()}</VariantPageViews>
    <VariantConversionRate>
      <ConversionRate rate={rate} displayRate={displayRate} />
    </VariantConversionRate>
    <BestCell>{best && <BestAward>best result</BestAward>}</BestCell>
  </BodyRow>
)

Table.propTypes = {
  test: PropTypes.object.isRequired,
}

export default Table
