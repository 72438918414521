import React, { Component } from 'react'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { signUp } from '../../actions/auth'
import { parseQuery } from '../../library/helpers'
import Error from '../Error'
import PasswordFields from './password_fields'
import Auth from './'
import {
  StyledForm,
  Header,
  AuthInput,
  Alternates,
  Alternate,
} from '../Style/auth'
import { Button } from '../Style/button'

const SubHeader = styled(Header)`
  color: #50c4b8;
`

const TermsContainer = styled.div`
  overflow: hidden;
  margin-bottom: 25px;
`

const Checkbox = styled(Field)`
  float: left;
  height: 45px;
  margin-right: 15px;
`

const Terms = styled.label`
  overflow: hidden;
  color: ${props => (props.hasError ? '#fa7777' : '#7e7e7e')};
  line-height: 1.4;

  a {
    font-weight: 600;
    color: ${props => (props.hasError ? '#fa7777' : '#7e7e7e')};
  }
`

class SignUp extends Component {
  render() {
    const { signUp, shop } = this.props
    return (
      <Auth>
        <Header>sign up with tweeker</Header>
        <SubHeader>the first two weeks are on us.</SubHeader>
        <Error />
        <SignUpForm signUp={signUp} shop={shop} />
        <Alternates>
          <Alternate>
            already have an account? <Link to="/log-in">log in</Link>.
          </Alternate>
        </Alternates>
      </Auth>
    )
  }
}

const SignUpForm = ({ signUp, shop }) => (
  <Formik
    initialValues={{
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      terms: false,
      shopify: shop
    }}
    validationSchema={Yup.object({
      name: Yup.string()
        .required('Required'),
      email: Yup.string()
        .email('Must be a valid email address')
        .required('Required'),
      password: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters'),
      password_confirmation: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      terms: Yup.boolean()
        .oneOf([true], 'Must agree!'),
      shopify: Yup.string()
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      signUp(values)
    }}
  >
    {form => (
      <StyledForm>
        <AuthInput type="text" label="Full Name" name="name" />
        <AuthInput
          type="email"
          label="Email Address"
          name="email"
          autoComplete="username"
        />
        <PasswordFields />
        <TermsContainer>
          <Checkbox
            type='checkbox'
            name='terms'
            id='terms'
          />
          <Terms
            htmlFor='terms'
            hasError={form.errors.terms && form.touched.terms}
          >
            By signing up you agree to our {' '}
            <a
              href='https://tweeker.io/terms'
              target='_blank'
              rel='noopener noreferrer'>Terms of Service</a>
            {' '} and to our {' '}
            <a
              href='https://tweeker.io/privacy'
              target='_blank'
              rel='noopener noreferrer'>Privacy Policy</a>
            {' '} or in case you are a European resident to our {' '}
            <a
              href='https://tweeker.io/gdpr'
              target='_blank'
              rel='noopener noreferrer'>EU Privacy Policy</a>.
          </Terms>
        </TermsContainer>
        <Field type='hidden' name='shopify' />
        <Button type="submit" value="get started" disabled={form.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)

function mapStateToProps(_, ownProps) {
  return {
    shop: parseQuery(ownProps, 'shop'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signUp: bindActionCreators(signUp, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
