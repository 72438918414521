import React from 'react'
import PropTypes from 'prop-types'
import Pricing from './pricing'

const Plan = ({ plan, showButton, cta, disabled, url }) => (
  <Pricing
    name={plan.name}
    cost={plan.cost}
    views={plan.views}
    url={url}
    showButton={showButton}
    cta={cta}
    disabled={disabled}
  >
    {plan.features.map((feature, index) => (
      <li key={index}>{feature}</li>
    ))}
  </Pricing>
)

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
}

export default Plan
