import React from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Input from '../Form/input'
import { InlineButton } from '../Style/button'
import { URL_REGEX } from '../../constants/helpers'

const width = '700px'

export const StyledForm = styled(Form)`
  overflow: hidden;
`

export const InputContainer = styled.div`
  overflow: hidden;
  float: left;
  width: 84.5%;

  @media (max-width: ${width}) {
    width: 100%;
    float: none;
  }
`

export const FloatButton = styled(InlineButton)`
  float: right;
  margin-left: 10px;

  @media (max-width: ${width}) {
    float: none;
    margin-left: 0;
    display: block;
    width: 100%;
  }
`

const UrlForm = ({ onSubmit, placeholder, submit, value }) => (
  <Formik
    initialValues={{ url: value }}
    validationSchema={Yup.object({
      url: Yup.string()
        .matches(URL_REGEX, 'Must be a valid URL')
        .required('Required')
    })}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setSubmitting(false)
      resetForm({ url: '' })
      onSubmit(values)
    }}
  >
    {props => (
      <StyledForm>
        <InputContainer>
          <Input
            type='text'
            name='url'
            autoFocus
            placeholder={placeholder}
            onBlur={() => {
              props.setFieldValue('url', formatUrl(props.values.url))
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.setFieldValue('url', formatUrl(props.values.url))
                props.handleSubmit()
              }
            }}
          />
        </InputContainer>
        <FloatButton type='submit' value={submit} disabled={props.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)

export const formatUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url)
  newUrl = newUrl.trim().replace(/\s/g, "")

  if (url && /^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }

  if (url && !/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`
  }

  return newUrl
};

export default UrlForm