import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const header = (state = initialState.header, action) => {
  switch (action.type) {
    case actionTypes.SET_HEADER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default header
