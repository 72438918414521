import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const test = (state = initialState.test, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TEST_SUCCESS:
    case actionTypes.UPDATE_TEST_SUCCESS:
    case actionTypes.SAVE_URL_SUCCESS:
    case actionTypes.DELETE_URL_SUCCESS:
      return action.payload
    case actionTypes.SET_TEST:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default test
