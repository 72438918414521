import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { logIn } from '../../actions/auth'
import Auth from './'
import Error from '../Error'
import {
  StyledForm,
  Header,
  AuthInput,
  Alternates,
  Alternate,
} from '../Style/auth'
import { Button } from '../Style/button'

class LogIn extends Component {
  render() {
    return (
      <Auth>
        <Header>log in to tweeker</Header>
        <Error />
        <LogInForm logIn={this.props.logIn} />
        <Alternates>
          <Alternate>
            <Link to="/sign-up">sign up</Link> for tweeker
          </Alternate>
          <Alternate>
            <Link to="/forgot-password">forgot your password?</Link>
          </Alternate>
        </Alternates>
      </Auth>
    )
  }
}

const LogInForm = ({ logIn }) => (
  <Formik
    initialValues={{ email: '', password: '' }}
    validationSchema={Yup.object({
      email: Yup.string()
        .email('Must be a valid email address')
        .required('Required')
        .max(255),
      password: Yup.string()
        .required('Required')
        .min(8, 'Minimum 8 characters')
    })}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false)
      logIn(values)
    }}
  >
    {form => (
      <StyledForm>
        <AuthInput
          type="email"
          label="Email Address"
          name="email"
          autoComplete="username"
        />
        <AuthInput
          type="password"
          label="Password"
          name="password"
          autoComplete="current-password"
        />
        <Button type="submit" value="log in" disabled={form.isSubmitting} />
      </StyledForm>
    )}
  </Formik>
)

function mapDispatchToProps(dispatch) {
  return {
    logIn: bindActionCreators(logIn, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(LogIn)
