import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Beakers } from '../Style/auth'
import { redirect, loginExpired } from '../../library/redirect'

class Auth extends Component {

  componentDidMount() {
    document.body.classList.add('auth')
  }

  componentDidUpdate() {
    const { loggedIn, loginExpired } = this.props

    if (loggedIn && !loginExpired) {
      redirect('/')
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('auth')
  }

  render() {
    return (
      <Container>
        <Beakers />
        {this.props.children}
      </Container>
    )
  }
}

Auth.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  loginExpired: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    loggedIn: !!state.currentUser.jwt,
    loginExpired: loginExpired(state.currentUser)
  }
}

export default connect(mapStateToProps)(Auth)