import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { logOut, getCurrentUser } from '../../actions/auth'
import Flash from '../Flash'
import { Container } from '../Style/container'
import { backgroundImage } from '../../library/style'
import logo from '../../images/logo.svg'
import user from '../../images/user.svg'
import arrow from '../../images/arrow.svg'

const HeaderContainer = styled.div`
  z-index: 3;
  margin-bottom: 50px;
`

const MainHeaderContainer = styled(Container)`
  position: relative;
  padding: 20px 0;
`

const LogoLink = styled(Link)`
  ${backgroundImage(logo, 40, 125)}
  display: block;
`

const NavContainer = styled.div`
  position: absolute;
  right: 0;
  top: 20px;
  font-size: 13px;

  :hover ul {
    display: block;
  }
`

const NavName = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  padding: 20px;
  position: relative;
  top: 6px;
  color: #454545;
  z-index: 6;

  :before {
    content: '';
    ${backgroundImage(user, 21, 21)}
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 6px;
  }

  :after {
    content: '';
    ${backgroundImage(arrow, 6, 9)}
    display: inline-block;
    margin-left: 15px;
  }

  @media (max-width: 800px) {
    visibility: hidden;

    :after {
      content: '…';
      background: transparent;
      font-size: 18px;
      position: relative;
      top: -2px;
      visibility: visible;
    }

    :hover {
      visibility: visible;
    }
  }
`

const NavList = styled.ul`
  background: white;
  border-radius: 5px;
  padding: 60px 20px 20px;
  margin-top: -30px;
  display: none;
  box-shadow: 0 0 5px 0px #d3dbe8;
  position: relative;
  z-index: 5;
`

const NavListItem = styled.li`
  margin-bottom: 10px;
`

const NavLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #555;

  :hover {
    color: #5474c1;
  }
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.handleLogout = this.handleLogout.bind(this)
  }

  componentDidMount() {
    this.props.getCurrentUser()
  }

  render() {
    const { loggedIn, currentUser, header } = this.props

    if (!header.show) {
      return null
    }

    return (
      <HeaderContainer>
        <Flash />
        <MainHeaderContainer>
          <h1>
            <LogoLink to="/">Tweeker</LogoLink>
          </h1>
          {loggedIn && header.nav && (
            <NavContainer>
              <NavName to="/">{currentUser.name}</NavName>
              <NavList>
                <NavListItem>
                  <NavLink href="/">Dashboard</NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink href='/settings'>Account Settings</NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink onClick={this.openChat}>Contact Us</NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink onClick={this.handleLogout}>Log Out</NavLink>
                </NavListItem>
              </NavList>
            </NavContainer>
          )}
        </MainHeaderContainer>
      </HeaderContainer>
    )
  }

  handleLogout(event) {
    event.preventDefault()
    this.props.logOut()
  }

  openChat() {
    window.$crisp.push(['do', 'chat:open'])
  }
}

Header.propTypes = {
  currentUser: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,

  logOut: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    loggedIn: !!state.currentUser.jwt,
    header: state.header,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: bindActionCreators(logOut, dispatch),
    getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
