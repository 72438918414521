import React, { Component } from 'react'
import { Field, ErrorMessage } from 'formik'
import styled, { css } from 'styled-components'
import Label from './label'

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;

  ${({ halfWidth }) =>
    !!halfWidth &&
    `
    width: 49%;
    margin-right: 2%;
    display: inline-block;

    :nth-of-type(2n) {
      margin-right: 0;
    }

    @media (max-width: 600px) {
      width: 100%;
      margin-right: 0;
    }
  `}
`

export const TextfieldStyles = css`
  padding: 14px 15px;
  font: 500 16px 'Inter', sans-serif;
  outline: none;
  border: none;
  background: ${props => (props.errorStyle ? '#ffeeee' : '#fff')};
  border-color: ${props => (props.errorStyle ? '#ffcbcb' : '#fff')};
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: #525252;
  display: block;
  width: 100%;

  ::placeholder {
    color: #c7c7c7;
  }
`

const StyledField = styled.input`
  ${TextfieldStyles} :focus + label,
  :not(:placeholder-shown) + label {
    top: -5px;
    font-size: 10px;
    color: #525252;
    padding: 0 5px;
    left: 4px;
  }
`

const StableField = styled.input`
  ${TextfieldStyles}

  ::placeholder {
    color: #ccc;
  }
`

export const StyledError = styled.div`
  color: #fa7777;
  margin: 5px 0 0 5px;
`

class Input extends Component {
  render() {
    const { label, name, showLabel, halfWidth, ...props } = this.props

    if (showLabel) {
      return (
        <InputContainer halfWidth={halfWidth}>
          <Label name={name} label={label} showLabel={showLabel} />
          <CompleteField
            name={name}
            Component={StableField}
            {...props}
          />
          <ErrorMessage name={name} component={StyledError} />
        </InputContainer>
      )
    } else {
      return (
        <InputContainer halfWidth={halfWidth}>
          <CompleteField
            name={name}
            placeholder="&nbsp;"
            Component={StyledField}
            {...props}
          />
          <Label name={name} label={label} showLabel={showLabel} />
          <ErrorMessage name={name} component={StyledError} />
        </InputContainer>
      )
    }
  }
}

const CompleteField = ({ name, Component, ...props }) => (
  <Field id={name} name={name}>
    {({field, meta}) => (
      <Component
        {...field}
        {...props}
        errorStyle={(meta.touched && meta.error)}
      />
    )}
  </Field>
)

export const SimpleField = ({ ...props }) => (
  <CompleteField
    {...props}
    Component={StableField}
  />
)

export default Input