import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Input from '../Form/input'
import Select from '../Form/select'
import { ShownLabel } from '../Form/label'
import Error from '../Error'
import { Button } from '../Style/button'
import { Section } from '../Style/container'
import { setError } from '../../actions/error'
import { countries, states } from '../../constants/geography'

const FormContainer = styled.div`
  overflow: hidden;

  input[type='text'], select {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #dedede;
    border-radius: 0;
    padding: 3px 0 7px;
  }

  div:after {
    right: 0;
    top: 12px;
  }
`

const CardElementContainer = styled.div`
  border-bottom: 1px solid #dedede;
  padding: 0 0 10px 0;
`

const ElementProps = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: '"Inter", sans-serif',
      color: '#525252',
      fontWeight: '500',
    },
  },
}

const countryDropdownId = 'address-country'

class PaymentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showState: true,
      disabled: false
    }
    this.countryChange = this.countryChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { error }  = this.props
    console.log(error)
    if (!!error && prevProps.error !== error) {
      this.setState({ disabled: false })
    }
  }

  render() {
    const { payment } = this.props

    return(
      <Formik
        enableReinitialize
        initialValues={{
          name: payment.name,
          address_country: payment.address_country,
          address_line1: payment.address_line1,
          address_line2: payment.address_line2,
          address_city: payment.address_city,
          address_state: payment.address_state,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .required('Required'),
          address_line1: Yup.string()
            .required('Required'),
          address_city: Yup.string()
            .required('Required'),
        })}
        onSubmit={async (values) => {
          this.setState({ disabled: true })

          let { token, error } = await this.props.stripe.createToken(values)
          if (!!error) {
            this.props.setError(error.message)
          } else {
            this.props.onSubmit(token)
          }
        }}
      >
        {form => (
          <FormContainer>
            <Form>
              <Error />
              <Section>
                <Input
                  type="text"
                  label="Name on Card"
                  name="name"
                  showLabel
                />
                <CardElementContainer>
                  <ShownLabel>Card</ShownLabel>
                  <CardElement {...ElementProps} />
                </CardElementContainer>
              </Section>
              <Section>
                <Select
                  name='address_country'
                  label='Country'
                  onChange={this.countryChange}
                  options={countries}
                  id={countryDropdownId}
                />
                <Input
                  type="text"
                  name="address_line1"
                  label="Address"
                  showLabel
                />
                <Input
                  type="text"
                  name="address_line2"
                />
                <Input
                  type="text"
                  name="address_city"
                  label="City"
                  showLabel
                />
                {this.state.showState &&
                  <Select name='address_state' label='State' options={states} />
                }
              </Section>
              <Button
                type="submit"
                value={this.props.submitValue}
                disabled={this.state.disabled}
              />
            </Form>
          </FormContainer>
        )}
      </Formik>
    )
  }

  countryChange(event) {
    this.setState({ showState: (event.target.value === 'United States') })
  }
}

PaymentForm.propTypes = {
  stripe: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  submitValue: PropTypes.string.isRequired,

  setError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    payment: state.payment,
    error: state.error,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setError }, dispatch)
}

export default injectStripe(
  connect(mapStateToProps, mapDispatchToProps)(PaymentForm)
)