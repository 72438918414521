import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ellipsis from '../../images/ellipsis.svg'
import { deleteTest } from '../../actions/test'

const OverlayMenuContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  :hover > ul {
    display: block;
  }

  :hover > div {
    background-color: #000;
  }
`

const Dots = styled.div`
  mask-image: url(${ellipsis});
  background-color: #fff;
  height: 4px;
  width: 18px;
  position: relative;
  z-index: 2;
`

const MenuContainer = styled.ul`
  position: absolute;
  right: -10px;
  top: -10px;
  background: white;
  font-size: 14px;
  padding: 25px 10px 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0px #666;
  text-align: right;
  display: none;
`

const MenuItem = styled.li`
  margin-bottom: 10px;
  white-space: nowrap;

  :last-of-type {
    margin-bottom: 0;
  }
`

const MenuLink = styled(Link)`
  text-decoration: none;
`

const DeleteLink = styled.a`
  text-decoration: none;
  color: #f97777;
`

class Menu extends Component {
  constructor(props) {
    super(props)
    this.onDelete = this.onDelete.bind(this)
  }

  render() {
    const editLink = `/tests/${this.props.test.id}/edit`
    return (
      <OverlayMenuContainer>
        <Dots>...</Dots>
        <MenuContainer>
          <MenuItem>
            <MenuLink to={'/tests?url=' + this.props.url}>Edit</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to={editLink}>Change Name</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to={editLink}>Add URL</MenuLink>
          </MenuItem>
          <MenuItem>
            <DeleteLink onClick={this.onDelete}>Delete</DeleteLink>
          </MenuItem>
        </MenuContainer>
      </OverlayMenuContainer>
    )
  }

  onDelete() {
    if (window.confirm('Are you sure you want to delete this test?')) {
      this.props.deleteTest(this.props.test.id)
    }
  }
}

Menu.propTypes = {
  url: PropTypes.string.isRequired,
  test: PropTypes.object.isRequired,

  deleteTest: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteTest,
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(Menu)
