import React from 'react'
import { Container, Step } from './style'
import { InlineButton } from '../../Style/button'

const Shopify = ({ businessId, embedVersion}) => {
  const link = `https://shopify.tweeker.io?business_id=${businessId}&embed_version=${embedVersion}`
  return(
    <Container>
      <Step>Click the button below to start the shopify plugin installation process.</Step>
      <InlineButton href={link} as='a'>
        Install on my Shopify Store
      </InlineButton>
      <Step>Once you are done you'll be brought back here, then click the check button below to continue.</Step>
    </Container>
  )
}

export default Shopify