import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { STRIPE_API_KEY } from '../../constants/helpers'
import GA from '../../library/googleAnalytics'
import Header from '../Header'
import Authenticator from '../Auth/authenticator'
import Crisp from '../Crisp'
import { Container } from '../Style/container'
import Frame from '../Frame'
import Url from '../Url'
import Home from '../Home'
import SignUp from '../Auth/sign_up'
import LogIn from '../Auth/log_in'
import ForgotPassword from '../Password/forgot'
import NewPassword from '../Password/new'
import Embed from '../Embed'
import Plans from '../Plans'
import NewPlan from '../Plans/new'
import ConfirmPlan from '../Plans/confirm'
import Payment from '../Payment'
import Test from '../Test'
import TestEdit from '../TestEdit'
import Settings from '../Settings'
import Shopify from '../Shopify'

function App() {
  return (
    <StripeProvider apiKey={STRIPE_API_KEY}>
      <Router>
        {GA.init() && <GA.RouteTracker />}
        <Authenticator />
        <Header />
        <Crisp />
        <Container>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/log-in" component={LogIn} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/passwords/new/:password_reset_token"
              component={NewPassword}
            />
            <Route exact path="/tests/new" component={Url} />
            <Route exact path="/tests/:id" component={Test} />
            <Route exact path="/tests/:id/edit" component={TestEdit} />
            <Route exact path="/tests" component={Frame} />
            <Route exact path="/embed" component={Embed} />
            <Route exact path="/plans" component={Plans} />
            <Route exact path="/plans/from/:plan" component={NewPlan} />
            <Route exact path="/plans/from/:oldPlan/to/:newPlan" component={ConfirmPlan} />
            <Route exact path="/shopify" component={Shopify} />
            <Route
              exact
              path="/payment/:plan"
              render={props => (
                <Elements>
                  <Payment {...props} />
                </Elements>
              )}
            />
            <Route
              exact
              path="/settings"
              render={props => (
                <Elements>
                  <Settings {...props} />
                </Elements>
              )}
            />
          </Switch>
        </Container>
      </Router>
    </StripeProvider>
  )
}

export default App
