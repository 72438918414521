import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import * as storage from '../../library/storage'
import * as helpers from '../../constants/helpers'
import { redirect, loginExpired } from '../../library/redirect'

class AuthenticationRoute extends Component {
  render() {
    const { loggedIn, loginExpired, loggedOutPath } = this.props

    if ((!loggedIn || loginExpired) && !loggedOutPath) {
      redirect('/log-in', 'Please log back in to continue.')
    }
    return null
  }
}

AuthenticationRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loginExpired: PropTypes.bool.isRequired,
  loggedOutPath: PropTypes.bool.isRequired,
}

function mapStateToProps(_, ownProps) {
  const currentUser = storage.get(helpers.AUTH_KEY)

  return {
    loggedIn: loggedIn(currentUser),
    loginExpired: loginExpired(currentUser),
    loggedOutPath: loggedOutPath(ownProps.location.pathname),
  }
}

function loggedIn(currentUser) {
  return !!currentUser
}

const permittedLoggedOutPaths = [
  '/log-in',
  '/sign-up',
  '/forgot-password',
  '/passwords/new',
  '/shopify'
]

function loggedOutPath(pathname) {
  const matchesPath = permittedLoggedOutPaths.some(path => pathname.includes(path))
  return matchesPath
}

const Authenticator = () => <Route component={connect(mapStateToProps)(AuthenticationRoute)} />

export default Authenticator