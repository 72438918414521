import callApi from '../'

export function fetchTest(id) {
  return callApi(`tests/${id}`)
}

export function fetchTestByUrl(url) {
  return callApi(`tests/by-url?url=${url}`)
}

export function fetchTests() {
  return callApi('tests')
}

export function deleteTest(id) {
  return callApi(`tests/${id}`, 'DELETE')
}

export function updateTest(id, body) {
  return callApi(`tests/${id}`, 'PUT', body)
}
