import callApi from '../'

export function signUp(body) {
  return callApi('users', 'POST', body)
}

export function logIn(body) {
  return callApi('users/log-in', 'POST', body)
}

export function updateUser(body) {
  return callApi('users', 'PUT', body)
}