import * as actionTypes from '../../constants/actionTypes'
import * as storage from '../../library/storage'
import * as helpers from '../../constants/helpers'
import initialState from '../initialState'

const currentUser = (state = initialState.currentUser, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_SUCCESS:
      return action.response
    case actionTypes.CREATE_SESSION_SUCCESS:
      return action.response
    case actionTypes.GET_CURRENT_USER:
      const user = storage.get(helpers.AUTH_KEY) || initialState.currentUser
      return setLoaded(user)
    case actionTypes.LOG_OUT:
      return initialState.currentUser
    default:
      return state
  }
}

const setLoaded = (user) => (
  { ...user, ...{ loaded: true }}
)

export default currentUser
